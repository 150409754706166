.ml_main_sidebar{
    width: 240px;
    position: fixed;
    z-index: 110;
    height: 100vh;
    -webkit-transition: width .6s ease-in-out;
    -moz-transition: width .6s ease-in-out;
    -o-transition: width .6s ease-in-out;
    transition: width .6s ease-in-out;
    @media (max-width:968px){
        display:none;
    }
    .ml_main_nav_container {
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        width: 100%;
        max-width: 240px;
        height: 100%;
        z-index: 1;
        background-color: white;
        box-shadow: 0 2px 9px 1px rgba(0, 0, 0, 0.09);
        -webkit-box-shadow: 0 2px 9px 1px rgba(0, 0, 0, 0.09);
        -moz-box-shadow: 0 2px 9px 1px rgba(0, 0, 0, 0.09);
        -webkit-appearance: none;
        position: relative;
        -webkit-transition: width .6s ease-in-out;
        -moz-transition: width .6s ease-in-out;
        -o-transition: width .6s ease-in-out;
        transition: width .6s ease-in-out;

        @media (max-width:700px){
            display:none;
        }

        .ml_logo-meludus{
            position: absolute;
            top: 0px;
            left: 0px;
            width: 70px;
            margin-top: 20px;
            margin-left: 70px;
            -webkit-transition: left .6s ease-in-out;
            -moz-transition: left .6s ease-in-out;
            -o-transition: left .6s ease-in-out;
            transition: left .6s ease-in-out;
        }   

        .ml_nav-compress-icon {
            font-size: 35px;
            font-weight: bolder;
            position: absolute;
            top: 19px;
            right: 20px;
            cursor: pointer;
            transform: rotate(90deg);
            transition: transform .6s ease-in-out;
        }

        .sidebar-body{
            position: relative;
            margin-top: 100px;
        }

        ul{
            list-style-type: none;
            color: $phgrey;
            padding-left: 5px;
            position: relative;
            margin-bottom: 22px;
            label{
                display: block;
                width: 100%;
                color: theme('colors.primary')!important;
                margin-bottom: 5px;
                margin-left: -13px;
                padding-left: 35px;
                margin-top: 20px;
                overflow: hidden;
                -webkit-transition: width .6s ease-in-out;
                -moz-transition: width .6s ease-in-out;
                -o-transition: width .6s ease-in-out;
                transition: width .6s ease-in-out;
            }
            .ml_legal{
                font-weight: 600!important;
            }
            li{
                cursor: pointer;
                padding: 5px;
                position: relative;
                margin-left: 10px;
                user-select: none;

                * { pointer-events: none; }

                p{
                    overflow: hidden;
                    display: inline-block;
                    width: 70%;
                    -webkit-transition: width .6s ease-in-out;
                    -moz-transition: width .6s ease-in-out;
                    -o-transition: width .6s ease-in-out;
                    transition: width .6s ease-in-out;
                    vertical-align: middle;
                    color: $lgrey
                }

                &:after{
                    content: '';
                    height: 0px;
                    width: 0px;
                    position: absolute;
                    top: 6px;
                    left: -13px;
                    border-top: 10px solid transparent;
                    border-left: 10px solid var(--primary);
                    border-bottom: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-radius: 5px;
                    opacity: 0;
                    transition: opacity .5s ease-in-out;
                }

                &.ml-li-select{
                    font-weight: 700!important;
                    p { color: $dark; }
                    &:after{
                        opacity: 1;
                    }
                }

                &.ml-pointer-none{
                    pointer-events: none;
                }
                &:hover{
                    p, .ml-icon{
                        color: theme('colors.secondary');
                        font-weight: 700!important;
                    }
                }
            }
            &:after{
                content:"";
                width: 0px;
                height: 1px;
                background: theme('colors.primary');
                display: block;
                margin-left: 15px;
                position: absolute;
                bottom: -18px;
                -webkit-transition: width .6s ease-in-out;
                -moz-transition: width .6s ease-in-out;
                -o-transition: width .6s ease-in-out;
                transition: width .6s ease-in-out;
            }
        }
    }
}

.mark_menuBar{
    z-index: 504;
    pointer-events: none;
}

.ml_menu_compressed{
    .ml_main_sidebar{
        width: 80px;
        .ml_main_nav_container{
            width: 80px;
            .ml_logo-meludus{
                left: -170px;
            }
            .ml_nav-compress-icon {
                transform: rotate(-90deg);
                width: 45px;
                right: 20px;
                margin: 0 auto;
            }
            ul{
                li:hover > i{
                    color: theme('colors.secondary');
                    font-weight: 700!important;
                }
                p, label{
                    width: 0px;
                }
                span{
                    display: block !important;
                    height: 30px;
                    position: relative !important;
                    top: 0;
                    background-repeat: no-repeat !important;
                }
                &:after{
                    width: 35px;
                }
            }
        }
    }
}

