.ml_section_book_index {
  .book-panel {
    display: -webkit-box;
    gap: 10px;
    background-color: #E1E8ED;
    overflow: auto;
    width: calc(100% - 40px);

    .nav-tree-hover {
      position: fixed;
      left: 0px;
      top: 0px;
      background-color: white;
      padding: 15px;
      z-index: 150;
      border-radius: 15px;
      @include shadow;
      opacity: 0;
      max-width: 300px;
      transition: all .3s ease-in-out;

      &.show-tooltip {
        opacity: 1;
      }
    }

    .navigation {
      z-index: 9;
      bottom: 17px;
      left: calc(50% + 100px);
      transform: translateX(-50%);
      transition: all .5s ease-out;

      input {
        width: 50px;
        height: 18px;
        border-radius: 24px;
        text-align: center;
        border: 0px;
        background: white;
      }
    }

    .book-sidebar {
      background-color: white;
      margin-top: 1px;
      position: relative;

      .tooltip-box {
        position: absolute;
        background-color: white;
        @include shadow;
        padding: 3px 10px;
        visibility: hidden;
        z-index: 10;

        &.show {
          visibility: visible;
        }
      }

      .ml-icon-doble-flecha {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
        position: absolute;
        top: 10px;
        right: 0px;
        cursor: pointer;
        transition: all .5s ease-in-out;
        transform: rotate(90deg);

        &:hover {
          transform: rotate(90deg) scale(1.2);
        }
      }

      .vertical-bar {
        width: 40px;
        background-color: var(--primary);
      }

      .sidebar-body {
        width: 280px;
        transition: all .5s ease-in-out;
        overflow: hidden;

        .container {
          box-sizing: border-box;
          padding: 0px 15px;

          .body {
            height: calc(100vh - 250px);
            overflow: auto;
          }
        }
      }
    }

    .book {
      margin-top: 15px;
      width: calc(100% - 430px);
      height: calc(100vh - 125px);
      overflow: auto;
      max-width: 1140px;
      padding: 5px 10px;
      transition: all .5s ease-in-out;

      .book-page {
        position: relative;
        background-color: white;
        width: 100%;
        aspect-ratio: 2 / 3;
        @include shadow;
        overflow: hidden;
        padding: 80px 40px;
        box-sizing: border-box;
        margin-bottom: 15px;
      }

      &.book-mode {
        margin-bottom: 10px;
        height: calc(100vh - 130px);
      }
    }

    .book-menu {
      margin-top: 15px;
      box-sizing: border-box;
      width: 70px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      height: calc(100vh - 150px);

      .search-input {
        left: -270px;
        top: 0px;
        visibility: hidden;
        opacity: 0px;
        transition: all .3s ease-out;

        &.show-input {
          visibility: visible;
          opacity: 1;
        }
      }

      .segment {
        .option {
          position: relative;
          width: 40px;
          height: 40px;
          cursor: pointer;
          transition: all .3s ease-in-out;

          i,
          .square {
            pointer-events: none;
            position: absolute;
            transition: all .3s ease-in-out;
          }

          .square {
            width: 40px;
            height: 40px;
            border-radius: 15px;
            background-color: white;
            box-shadow: 0px 0px 4px #0000000B;
            ;
            z-index: 0px;
            transform: rotate(45deg);
          }

          i {
            z-index: 1;
            margin: auto;
            color: var(--primary);
          }

          &:hover {
            transform: scale(1.1);

            i {
              font-weight: bold;
            }
          }

          &.active {
            .square {
              background-color: var(--primary);
            }

            i {
              color: white;
            }
          }

          &.disabled {
            opacity: .5 !important;
            pointer-events: none !important;
          }
        }
      }
    }

    &.fold {
      .navigation {
        left: 50%;
      }

      .book-sidebar {
        .sidebar-body {
          width: 0px;
        }

        .ml-icon-doble-flecha {
          color: white;
          transform: rotate(-90deg);

          &:hover {
            transform: rotate(-90deg) scale(1.2);
          }
        }
      }

      .book {
        width: calc(100% - 150px);
      }
    }
  }
}