@use "../../../styles" as *;

.section-create_memory{
  .ml_modal_content{
    .config-block {
      display: flex;
      flex-direction: column;

      @include bpFrom(tablet){
        flex: 1 0 45%;
      }

      h3 {
        display: flex;
        align-items: center;
        margin-bottom: spacing(1);
      }

      .content {
        padding-left: spacing(8);

        > p {
          color: color(text, subtitle);
          margin-bottom: spacing(2);
        }
      }
    }

    #tree_content_wrap {
      width: 100%;
      height: 550px;
      overflow: auto;
      padding: 20px 0px;
    }
  }

  .ml_generate_memo_tests{
    padding: 0 spacing(35);
    padding-top: spacing(17);

    h1 {
      display: flex;
      align-items: center;
      margin-bottom: spacing(2);
    }

    .tests-content-section{
      margin-top: spacing(10);
      display: flex;

      .config-block {
        display: flex;
        flex-direction: column;
        flex: 1 0 45%;
        position: relative;

        .it-hover {
          position: absolute;
          top: -120px;
          right: 0px;
          background-color: white;
          padding: 15px 25px;
          box-sizing: border-box;
          width: 316px;
          border-radius: 10px;
          @include shadow;
          display: none;
        }

        &.w-100 {
          width: 100%;
          flex: 1 0 100%;
        }

        h3 {
          display: flex;
          align-items: center;
          margin-bottom: spacing(1);
        }

        .content {
          padding-left: spacing(8);

          > p {
            color: color(text, subtitle);
            margin-bottom: spacing(2);
            &.ml_btn{
              color: var(--primary);
              cursor: pointer;
              i { pointer-events: none;}
            }
          }

          .content-row {
            display: flex;
            .questions-num {
              max-width: 157px;
              padding: 5px 10px;
            }
          }

          .radio-wrap {
            display: flex;
            flex-direction: column;
            row-gap: spacing(4);
          }

          .tree-select { max-width: 200px; }

          .ml_input_wrap {
            display: flex;
            margin: spacing(2) 0;
          }
        }

        &.sh-hover:hover{
          .it-hover { display: block; }
        }
      }

      .tree-content {
        flex: 1 1 30%;
        max-width: 30%;
        overflow-x: auto;

        .tree-select {
          max-width: 250px;
          margin-bottom: spacing(7);
        }

        .tree_path {
          display: flex;
          flex-direction: column;
          gap: 15px;
          padding: 15px 0px;
          .ml_item_tree{
            position: relative;
            color: $ldark;
            &:before{
              content: "";
              position: absolute;
              width: 10px;
              height: 10px;
              background-color: var(--primary);
              border-radius: 5px;
              top: 5px;
              left: -20px;
            }
          }
        }
      }

      .tests-configuration {
        flex: 1 1 70%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        align-content: flex-start;
        row-gap: spacing(2);
        padding-left: spacing(5);
      }
    }

    .bottom-left-bg {
      right: 0;
      bottom: -840px;
      border-radius: 30%;
      background: #e7e2fa;
      width: 70vw;
      height: 60vw;
      transform: rotate(17deg);
      position: fixed;
      z-index: -1;
    }
  }

  .ml_generate_memo_tests .tests-content-section #tree_content_wrap {
    max-height: 450px;
  }
}

