@import './root/styles.scss';
@import './user-panel/styles.scss';

// TEST
@import './test/index/styles.scss';
@import './test/create/styles.scss';
@import './test/do/styles.scss';
@import './test/summary/styles.scss';
// ---- END TEST

// BOOK
@import './book/index/styles.scss';
// END BOOK

@import './memory/index/styles.scss';
@import './memory/create/styles.scss';
@import './memory/do/styles.scss';

@import './not_found/styles.scss';
@import './register/styles.scss';
@import './planning/styles.scss';
@import './my-courses/styles.scss';
@import './my-courses-individual/styles.scss';
@import './invite-friends/styles.scss';
@import './flash-link/styles.scss';
@import './achievements/styles.scss';
@import './rankings/styles.scss';
@import './u_deconstructor/styles.scss';

.unity-section {
    width: 100%;
    height: calc(100dvh - 115px);
    background: white;
}

@media (min-width: 968px) {
    .unity-section {
        height: calc(100dvh - 65px);
    }
}

