@use "../../styles" as *;

.ml_planning_wrap{
  @apply mx-16 flex flex-col;
  
  .title {
    @apply text-xl font-semibold;
  }

  h1{
    margin-top: 0;
  }
}

