@use "./styles" as *;
@use "bed-path-dot-home/sass/vars/styles.scss" as vars;


@layer components {
  .shadow-ml {
    box-shadow: 0 0.2rem 0.9rem 0.1rem rgba(0, 0, 0, .09);
    -webkit-box-shadow: 0 0.2rem 0.9rem rgba(0, 0, 0, .09);
    -moz-box-shadow: 0 .2rem .9rem rgba(0, 0, 0, .09);
    -webkit-appearance: none;
  }
}

@import 'bed-path-dot-home/guide_lines_all.css';
@import 'bed-path-dot-home/sass/vars/styles.scss';
@import 'components/styles.scss';

:root{
  --txt-bg: 24px;
  --txt-md: 20px;
  --txt-sm: 16px;
  --txt-xs: 12px;
}

@import 'screens/styles.scss';

html {
  font-size: 62.5%;
  height: 100%;
  width: 100%;
  color: vars.$dark;
  box-sizing: border-box;
}

* {
  font-smooth: always;
  scrollbar-width: thin;
  scrollbar-color: var(--primary) white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  button{
    font-family: "Spartan", sans-serif !important;
  }
}

body {
    width: 100%;
    height: 100%;
  &.block-scroll {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }
  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 50%;
  }
  &::-webkit-scrollbar-track {
      background: white;
  }
  &::-webkit-scrollbar-thumb {
      background: var(--primary);
  }
  #main_classroom_wrap{
    display: flex;
    flex: 1 1 0;
    min-height: 100vh;
    position: relative;

    @media (max-width:968px){
      display: unset;
    }

    .ml_content_wrap {
      display: flex;
      flex: 1;
      flex-direction: column;
      width: 76%;
      padding-left: 0px;
      transition: all .6s ease-in-out;

      @media (max-width:968px){
        width:100%;
      }
    }
    &.ml_with_menu{
      .ml_content_wrap{
        padding-left: 240px;
        @media (max-width:968px){
          padding-left: 0px;
        }
      }
      &.ml_menu_compressed{
        .ml_content_wrap{
          padding-left: 81px;
          @media (max-width:968px){
            padding-left: 0px;
          }
        }
      }
    }
  }
}

#main_classroom_wrap {
  .loading-section {
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: white;
    width: 100%;
    z-index: 100;
    height: 100dvh;
    box-sizing: border-box;
    display: none;
  }

  .loading-section.show {
    display: block;
  }

  .loading-section .img-wrap {
    display: grid;
    width: 100%;
    height: 100%;
  }

  .loading-section .img-wrap img {
    width: 200px;
    margin: auto;
    max-width: auto;
    max-height: auto;
    transform: translateY(-90vh);
    transition: transform 1s cubic-bezier(0.55, 0.07, 0.64, 1.29);
  }

  .loading-section.animate .img-wrap img {
    transform: translate(-3px, 0px);
  }
}

.ml_cookies {
    width: 100%;
    background-color: $white;
    position: fixed;
    bottom: 0;
    text-align: center;
    color: $ldark;
    font-size: 14px;
    z-index: 99;
    box-shadow: 0 2px 20px 0 rgb(231, 231, 231);
    p {
        max-width: 80%;
        text-align: center;
        margin: auto;
        margin-top: 40px;
    }
    .ml_btn-cookies {
        display: block;
        width: max-content;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 40px;
    }
}

.hidden_i {
  display: none !important;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-family: "Spartan", sans-serif;
}
::-moz-placeholder { /* Firefox 19+ */
  font-family: "Spartan", sans-serif;
}
:-ms-input-placeholder { /* IE 10+ */
  font-family: "Spartan", sans-serif;
}
:-moz-placeholder { /* Firefox 18- */
  font-family: "Spartan", sans-serif;
}

.global-toast {
  position: fixed;
  max-width: 600px;
  width: 80%;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 20px 40px;
  margin-inline: 29px;
  top: 20px;
  right: 20px;
  transform: translateY(-100%);
  z-index: 10;
  background-color: #ABF5DB;
  opacity: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, 
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  p {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    line-height: 28px;
  }
}

.goal-date-picker {
  position: relative;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 5px 20px;
  background-color: var(--primary5);
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: fit-content;
  cursor: pointer;

  span, i, input {
    pointer-events: none;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: #0A2327;
    text-align: center;
    width: 110px;
    line-height: 28px;
  }

  i {
    margin: 0px;
    padding: 0px;
    font-size: 30px;
    color: var(--primary);
    width: 30px;
    height: auto;
  }

  input {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    margin-inline: auto;
    width: 10px;
    opacity: 0;
  }
}


.ml-mobile-selector {
  position: relative;

  &.active .placeholder {
    background-color: var(--warning);
  }

  &.active .placeholder i {
    color: #0A2327;
  }

  &.open .placeholder i {
    transform: rotate(180deg);
  }

  &.open .option-container {
    max-height: 340px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .placeholder {
    display: flex;
    align-items: center;
    column-gap: 5px;
    justify-content: space-between;
    border-radius: 20px;
    padding: 5px 20px;
    box-sizing: border-box;
    background-color: var(--primary5);
    transition: background-color .3s linear;
    cursor: pointer;
  }

  .placeholder span {
    font-size: 14px;
    line-height: 28px;
    pointer-events: none;
  }

  .placeholder i {
    font-size: 30px;
    margin: 0px;
    padding: 0px;
    width: auto;
    height: auto;
    pointer-events: none;
    transition: transform .3s linear, color .3s linear;
  }

  .option-container {
    position: absolute;
    top: 100%;
    left: 0px;
    width: 100%;
    background-color: white;
    border-radius: 20px;
    transition: max-height .3s linear;
    max-height: 0px;
    z-index: 11;
    overflow: hidden;
  }

  .option-container .option-card {
    background-color: white;
    box-sizing: border-box;
    padding: 20px 20px;
  }

  .option-container .option-card .option-scroll {
    max-height: 300px;
    overflow-y: auto;
  }

  .option-container .option-card .option-scroll .option-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-sizing: border-box;
    padding-inline: 10px;
  }

  .option-list .option-item {
    font-size: 14px;
    line-height: 28px;
    cursor: pointer;
  }

  .option-list .option-item.selected {
    font-weight: 500;
  }
}

.log-section {
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  z-index: 9000;
  background: #0A2327;

  &.show .log-section-body {
    height: 200px;
  }

  &.show .log-section-header>div::before {
    content: '-';
  }

  .log-section-header {
    display: flex;
    align-items: center;
    column-gap: 20px;
    box-sizing: border-box;
    padding: 10px 29px;
  }

  .log-section-header p {
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
  
  .log-section-header p.close {
    text-decoration: underline;
    margin-left: auto;
    cursor: pointer;
  }

  .log-section-header>div::before {
    content: '+';
    color: white;
    font-size: 30px;
    font-weight: bolder;
    text-align: center;
    width: 40px;
  }

  .log-section-body {
    height: 0px;
    overflow-y: auto;
    padding-inline: 29px;
    transition: height 0.3s ease-in-out;
  }

  .log-section-body .log-items {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    box-sizing: border-box;
    padding-top: 20px;
    background: #01393A;
    height: 100%;
  }

  .log-section-body .log-items p {
    color: white;
    font-size: 12px;
    line-height: 20px;
  }
}

.ml_input-cb {
  background-color: var(--primary4);
}


// width >= 968px
@media (min-width: 968px) {
  #main_classroom_wrap {
    &.ml_menu_compressed .loading-section {
      padding-left: 81px;
    }

    .loading-section {
      padding-left: 240px;
    }

    .loading-section.full-width {
      padding-left: 0px !important;
    }
  }
}
