.ml_arbol-contenido{
  font-size: 14px;
	
	box-sizing: border-box;
	padding-right: 10px;
	margin-left: -5px;
	position: relative;
	margin-right: 0px;

	.ml_item-ctree{
		display: flex;
		position:relative;

		&.no_child{ margin-left: 44px; }

		&.noQus{
			i,.ml_item_text{
				filter: grayscale(.5);
				opacity: .4!important;
			}

			*{ pointer-events: none; }

			&::before{
				content: "estamos mejorándotelo 🤩";
				position: absolute;
				background-color: white;
				padding: 5px 15px;
				border-radius: 10px;
				top: -30px;
				box-shadow: 0 0.2rem 0.9rem 0.1rem rgba(0,0,0,.09);
				-webkit-box-shadow: 0 0.2rem 0.9rem rgba(0,0,0,.09);
				-moz-box-shadow: 0 .2rem .9rem rgba(0,0,0,.09);
				-webkit-appearance: none;
				display: none;
			}

			&:hover{
				&::before{
					display: unset;
				}
			}
		}

		i{
			cursor: pointer;
			width: auto;
			height: auto;

			&.ml-icon-flecha_desplegar {
				margin-left: 0px;
				transition: transform .3s ease-in-out;

				&.active { transform: rotate(-90deg); }

				&.hide {
					opacity: 0;
				}
			}

			&.ml-icon-temario {
				margin-right: 0px;
				opacity: 1;

				&.hide {
					opacity: 0;
				}
			}
		}

		.ml_item_text{
			display: flex;
			flex: 1;
			align-items: flex-start;
			gap: 5px;
			padding: 6px 0px;

			input {
				margin: 0px;
				width: 20px;
				height: 20px;
			}

			label { 
				padding-top: 3px; 
			}
		}
	}

	.ml_statistics_row{
		position: relative;
		background-color: white;
		border-radius: 20px;
		box-sizing: border-box;
		margin: 3px 5px 3px auto;
		width: fit-content;
		overflow: hidden;
		box-shadow: 0 0.2rem 0.9rem 0.1rem rgba(0,0,0,.09);
		-webkit-box-shadow: 0 0.2rem 0.9rem rgba(0,0,0,.09);
		-moz-box-shadow: 0 .2rem .9rem rgba(0,0,0,.09);
		-webkit-appearance: none;
		min-height: 0px;
		max-height: 0px;
		height: auto;
		padding-inline: 20px;
		transition: max-height .3s ease-in-out;

		&.show{ 
			max-height: 100px;

			@media(max-width: 800px){
				max-height: 220px;
			}
		}

		&.w-data {
			> p { display: none; }
			> ul { display: grid; }
		}

		> i {
			position: absolute;
			top: 0px;
			bottom: 0px;
			right: 10px;
			@media (max-width: 800px) {
				top: 10px;
			}

			&:hover {
				font-weight: bolder;
				cursor: pointer;
			}
		}

		> ul { 
			display: hidden;
			grid-template-columns: 1fr;
			list-style: none;
			padding: 0px;
			margin-right: 40px;
			margin-block: 20px;
			row-gap: 15px;

			@media (min-width: 680px) {
				grid-template-columns: 1fr 1fr;
				column-gap: 30px;
			}

			@media (min-width: 900px) {
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}

			li {
				display: flex;
				align-items: center;
				column-gap: 5px;

				i.ml-icon-pregunta{
					margin: 0px 3px 0px 0px;
					width: 23px;
					height: 23px;
				}

				.ml_point{
					display: inline-block;
					width: 20px;
					height: 20px;
					border-radius: 3px;
					position: relative;

					&.ml_point-check:after,
					&.ml_point-check:before,
					&.ml_point-wrong:after,
					&.ml_point-wrong:before,
					&.ml_point-na:after {
						content: '';
						position: absolute;
						background-color: white;
						border-radius: 4px;
						height: 3px;
					}

					&.ml_point-check{
						&:after{
							top: 9px;
							left: 7px;
							width: 11px;
							transform: rotate(-45deg);
						}
						&:before{
							top: 10px;
							left: 4px;
							width: 6px;
							transform: rotate(45deg);
						}
					}

					&.ml_point-wrong{
						&:after{
							top: 7px;
							left: 4px;
							width: 12px;
							transform: rotate(-45deg);
						}
						&:before{
							top: 7px;
							left: 4px;
							width: 12px;
							transform: rotate(45deg);
						}
					}

					&.ml_point-na:after{
						top: 8px;
						left: 4px;
						width: 12px;
					}
				}
			}
		}
	}

	.ml_st-level-ctree{
		margin-bottom: 10px;
	}

	.ml_nd-level-ctree, 
	.ml_rd-level-ctree, 
	.ml_fourth-level-ctree, 
	.ml_fifth-level-ctree{
		margin-left: 7px; 

		@media (min-width: 800px) { 
			margin-left: 15px;
		}
	}
}

