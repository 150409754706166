//media query a partir de 768px
@media (max-width: 768px) {
    .title_4{
        font-size: 14px!important;
    }
    .title_6 {
        font-size: 14px!important;
    }
    .p_14 {
        font-size: 12px!important;
    }
}
