@use "../abstracts" as *;
@use "sass:map" as *;

/* Gets the breakpoint size based on size specified */
@function getBpSize($size) {
  @if not map-has-key($breakpoints, $size) {
    @error "Unknown #{$size} breakpoint";
  }

  @return map-get($breakpoints, $size);
}


/* Set rules for element from breakpoint specified */
@mixin bpFrom($size) {
  @if not map-has-key($breakpoints, $size) {
    @error "Unknown #{$size} breakpoint";
  }

  $bpSize: getBpSize($size);

  @if $bpSize == 0 {
    @content;
  } @else {
    @media (min-width: $bpSize) {
      @content;
    }
  }
}

@mixin bpFromMin($size) {
  @if not map-has-key($breakpoints, $size) {
    @error "Unknown #{$size} breakpoint";
  }

  $bpSize: getBpSize($size);

  @if $bpSize == 0 {
    @content;
  } @else {
    @media (max-width: $bpSize) {
      @content;
    }
  }
}
