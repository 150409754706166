@use "../../styles" as *;


.ml_my_courses_wrap{
  min-width: 70%;
  @apply max-w-9xl flex flex-col pt-16 mx-auto;
  
  .ml_btn-wrap.group{
    .ml_btn-link-p,.ml_btn-link-y{
      span.link-line{
        top: unset;
        @apply max-w-[105%] absolute bottom-0;
      }
    }
  }

  .shadow-ml {
    background: white;
  }

  .tests-configuration {
    flex: 1 1 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    row-gap: spacing(12);
    padding-left: spacing(5);

    img {
      max-width: toRem(32);
    }
    
    .config-block {
      display: flex;
      flex-direction: column;
      flex: 1 0 45%;

      &.w-100 {
        width: 100%;
        flex: 1 0 100%;
      }

      h3 {
        display: flex;
        align-items: center;
        margin-bottom: spacing(1);
        font-size: 1.4rem;
      }

      .content {
        padding-left: spacing(8);

        > p {
          color: color(text, subtitle);
          margin-bottom: spacing(2);
        }

        .content-row {
          display: flex;
          // > * {
          //   flex: 1 0 20%;
          // }
        }

        .questions-num {
          max-width: 57px;
          padding: 5px 10px;
        }

        .responses-select {
          max-width: 150px;
        }

        .radio-wrap {
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: spacing(4);
          margin-top: spacing(5);
        }
      }


      .ml_input_wrap {
        display: inline-block;
      }
    }

    .save-config-card {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 6px #00000015;
      border-radius: 20px;
      padding: spacing(10);
     

      .wrapper {
        display: flex;
        flex-direction: column;

        > p {
          display: flex;
          align-items: center;
          font-weight: 600;
        }

        .content {
          padding-left: 40px;
          display: flex;
          flex-direction: column;

          input {
            margin-top: spacing(2);
            max-width: 250px;
          }

          .content-hint {
            font: normal normal medium 16px/29px Spartan;
            letter-spacing: 0px;
            color: #01393A99;
          }
        }
      }

      &.w-100 {
        width: 100%;
        flex: 1 0 100%;
      }

    }
  }

  .bg-top {
    width: 900px;
    height: 700px;
    border-radius: 200px;
    position: fixed;
    top: -500px;
    left: 0;
    z-index: -5;
    transform: rotate(12deg);
    @apply bg-primary4;
  }

  .bg-right {
    width: 300px;
    height: 300px;
    border-radius: 100px;
    position: fixed;
    top: 70%;
    right: -40px;
    z-index: -5;
    transform: rotate(-17deg);
    @apply bg-warning4;
  }
}




