
.ml_aula_registration_wrap{
	.ml_logo{
		fill:#fff;	
	}
	.ml_registration-background{
		border-radius: 15%;
		padding: 20px;
		width: 2300px;
		height: 2300px;
		transform: rotate(-20deg);
		position: fixed;
		left: 30%;
		top: -30%;
		background-color:$white;
		@media (max-width: 1600px) {
	    	left: 30%;
		}
		@media (max-height: 950px) {
	    	left:40%;
	    }
	    @media (max-height: 950px) and (min-width: 700px) {
	    	top:-40%;
		}
		@media (max-width: 700px) {
			left: 20%;
	    	top: -210px;	
		}
	}
	.ml_registration-content{
		width: 380px;
		margin-top: 150px;
		position: relative;
		z-index: 90;
		text-align: center;	
		.ml_input_form{
			display: inline-block;
			width: 90%; 
			margin: 10px 0px;
			text-align: left;
		}
		.submit_form{
			display: block;
			width: 90%;
			margin: 0px auto;
		}
		.ml_input-cb-wrap{
			font-size: .8em;
			width: 90%;
			margin: 10px auto;
			.ml_rb_link{
				text-decoration: underline;
				&:hover {
					font-weight: bold;
					cursor: pointer;
				}
			}
		}
		.ml_btn-link-y{
			width: fit-content;
			margin: 0 auto;
			position: relative;
			* { pointer-events: none; }
			span{
				width: 100%;
				left: 0px;
				position: absolute;
				bottom: 0px;
				top: auto;
				transition: all .3s ease-in-out;
			}
			&:hover{
				span{
					top: auto;
					bottom: 0px;
					height: 15px;
				}
			}
		}
	}

	.ml_register_error, .ml_register_success{
		width: 300px;
		height: 300px;
		position: fixed;
		top: 0px;
		width: 100vw;
		z-index: 5;
		height: 100vh;
		img{
			width: 35vh;
			position: absolute;
			left: 50%;
		}
	}

	.ml_register_error{
		.ml_register_error_msg{
			width: 90%;
			text-align: center;
			top: 25vh;
		}
		.ml_reg_link{
			--font-size: .8em;
			bottom: 25vh;
		}
		.ml_reg_link, .ml_register_error_msg{
			opacity: 0;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
		img{
			transform: translateX(-50%);
			top: -35vh;
		}
		&.ml_animate_error{
			.ml_reg_link, .ml_register_error_msg{
				animation: ludo_msg 2s linear 1s;
				animation-fill-mode: forwards;
			}
			img{
				animation: ludo_falling 1s cubic-bezier(.85,.69,.25,1.07) 0s, ludo_bounding 2s 1s ease infinite;
			}
			&:after{
				content: '';
				position: absolute;
				bottom: 300px;
				left: 50%;
				transform: translateX(-50%);
				width: 0px;
				height: 5px;
				border-radius: 50%;
				background-color: $lgrey;
				opacity: .5;
				filter: blur(4px);
				animation: ludo_falling_shadow 1s cubic-bezier(.85,.69,.25,1.07) 0s, ludo_bounding_shadow 2s 1s ease infinite;
			}
			@keyframes ludo_msg{
				0%{
					opacity: 0;
				}
				100%{
					opacity: 1;
				}
			}
			@keyframes ludo_falling_shadow{
				0%{ 
					width: 0px;
				}
				100%{
					width: 120px;
				}
			}
			@keyframes ludo_bounding_shadow{
				0%{
					width: 120px;
					opacity: .8;
				}
				50%{
					width: 80px;
					opacity: .5;
				}
				100%{
					width: 120px;
					opacity: .8;
				}
			}
			@keyframes ludo_falling{
				0%{ 
					transform: translateX(-50%);
					top: -240px;
				}
				100%{
					transform: translateX(-50%);
					top: 40%;
				}
			}
			@keyframes ludo_bounding{
				0%{
					transform: translateX(-50%) rotate(0deg);
					top: 40%;
				}
				50%{
					transform: translateX(-50%) rotate(0deg);
					top: 39%;
				}
				100%{
					transform: translateX(-50%) rotate(0deg);
					top: 40%;
				}
			}
		}
	}

	.ml_register_success{
		.ml_register_success_msg{
			text-align: center;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 25vh;
			padding: 40px;
			border-radius: 40px;
			@apply shadow-ml;
			opacity: 0;
			&:after{
				content: '▲';
				height: 10px;
				width: 10px;
				position: absolute;
				left: 55%;
				bottom: -45px;
				transform: rotate(180deg);
				font-size: 50px;
				color: white;
				text-shadow: 0px -6px 8px rgba(0, 0, 0, 0.09);
			}
		}
		img{
			transform: translateX(-50%);
			top: -35vh;
		}
		&.ml_animate_success{
			.ml_register_success_msg{
				animation: ludo_msg_2 1s linear 1s;
				animation-fill-mode: forwards;
			}
			img{
				animation: ludo_falling_2 1s cubic-bezier(.85,.69,.25,1.07) 0s, ludo_bounding_2 2s 1s ease infinite;
			}
			&:after{
				content: '';
				position: absolute;
				bottom: 30vh;
				left: 50%;
				transform: translateX(-50%);
				width: 0px;
				height: 5px;
				border-radius: 50%;
				background-color: $lgrey;
				opacity: .5;
				filter: blur(4px);
				animation: ludo_falling_shadow_2 1s cubic-bezier(.85,.69,.25,1.07) 0s, ludo_bounding_shadow_2 2s 1s ease infinite;
			}
			@keyframes ludo_msg_2{
				0%{
					opacity: 0;
					top: 25vh;
				}
				100%{
					opacity: 1;
					top: 22vh;
				}
			}
			@keyframes ludo_falling_2{
				0%{ 
					transform: translateX(-50%);
					top: -35vh;
				}
				100%{
					transform: translateX(-50%);
					top: 40vh;
				}
			}
			@keyframes ludo_bounding_2{
				0%{
					transform: translateX(-50%);
					top: 40%;
				}
				50%{
					transform: translateX(-50%);
					top: 39%;
				}
				100%{
					transform: translateX(-50%);
					top: 40%;
				}
			}
			@keyframes ludo_falling_shadow_2{
				0%{ 
					width: 0px;
				}
				100%{
					width: 120px;
					opacity: .8;
				}
			}
			@keyframes ludo_bounding_shadow_2{
				0%{
					width: 120px;
					opacity: .8;
				}
				50%{
					width: 80px;
					opacity: .5;
				}
				100%{
					width: 120px;
					opacity: .8;
				}
			}
		}
	}

	@media (max-width: 800px) {
		.ml_register_success{
			.ml_register_success_msg{
				width: 60%;
				top: 15vh;
			}
		}
	}

	@media (max-width: 420px) {
		.ml_registration-content{
			width: 90%;
			max-width: 350px;
		}
		.ml_registration-title {
		    font-size: 30px;
		}
		.ml_register_success{
			.ml_register_success_msg{
				width: 85%;
				top: 25vh;
				font-size: .75em;
			}
		}
	}
}


