@use "../../styles" as *;

.achievement-page {
  min-height: 100dvh;
  box-sizing: border-box;
  position: relative;
  overflow: clip;
}

.achievement-page .main-title {
  padding-top: 30px;
  padding-bottom: 50px;
  padding-inline: spacing(6);
  box-sizing: border-box;
  
  h2 {
    font-size: 22px;
    line-height: 34px;
    margin: 0px;
  }
}

.achievement-page div.card-container {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 40px;
  row-gap: 25px;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 840px;
  margin-inline: auto;
  padding-bottom: 50px;
  padding-inline: spacing(6);
  box-sizing: border-box;
}


.achievement-page .bg-square {
  width: 70%;
  aspect-ratio: 1 / 1;
  background: #F4F3C1;
  position: absolute;
  right: -26%;
  top: 25%;
  z-index: 0;
  border-radius: 18%;
  transform: rotate(55deg);
}


.achievement-page div.card-container .card {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-inline: auto;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 20px;
  background-color: white;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

  .card-img {
    width: 35%;
  }

  .card-img img {
    width: 100%;
    max-width: unset;
  }

  .card-content {
    flex: 1;
    padding: 20px 30px 20px 0px;
  }

  .card-content h3 {
    font-size: 20px;
    margin: 0px;
    line-height: 28px;
  }

  .card-content p.description {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .card-content p.level-legend {
    font-size: 14px;
    font-weight: 700;
    color: #707070;
  }

  .card-content .progress {
    background-color: rgba(204, 204, 204, 0.5);
    width: 100%;
    height: 8px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 5px;
  }

  .card-content .progress .progress-bar{
    height: 8px;
    background: #38E7ED;
    width: 0%;
    border-radius: 20px;
    transition: width 0.5s ease-in-out;
  }

  .card-content p.progress-number {
    font-size: 14px;
    font-weight: 500;
    color: #707070;
  }
}

.achievement-page div.card-container.show .card {
  opacity: 1;
  transform: translateY(0);
}


// width >= 768px 
@media (min-width: 768px) {
  .achievement-page div.card-container {
    grid-template-columns: 1fr 1fr;
  }

  .achievement-page div.card-container .card {
    max-width: 400px;
    margin-inline: unset;
  }
}


// width >= 800px 
@media (min-width: 800px) {
  .achievement-page .main-title,
  .achievement-page div.card-container {
    padding-inline: spacing(12);
  }
}


// width >= 1530px 
@media (min-width: 1530px) {
  .achievement-page div.card-container {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1290px;
  }
}
