@use "../../../styles" as *;

.do_memo_test{
  opacity: 0;
  &.ml_display{ opacity: 1; }
  .ml_do_memo_tests_wrap{
    padding-left: spacing(35);
    padding-top: spacing(17);
    height: 95vh;
    overflow: hidden;
    transition: opacity .4s ease-in-out;
    ::-webkit-scrollbar {
      height: 0px;
      width: 5px;
      background: transparent;
      z-index: 90;
    }

    ::-webkit-scrollbar-thumb {
      @apply bg-secondary;
      -webkit-border-radius: 20px;
    }


    h1 {
      margin: 0;
      margin-bottom: spacing(5);
    }

    .main-content-row {
      height: calc(100vh - 230px);
      display: flex;
      flex-direction: row;
      margin-left: -10px;
      position: relative;
      column-gap: 40px;
      padding-right: 20px;

      .tests-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        scroll-snap-type: y mandatory;
        overflow: auto;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        // box-shadow: inset 0 -7px 11px 6px white;
        padding: 10px;

        scrollbar-color: var(--primary) transparent;

        .big-test-card {
          scroll-snap-align: center;
          box-shadow: 1px 1px 5px 0 rgb(156 156 156);
          min-height: 95%;
          max-height: 95%;
          overflow: auto;
          overflow-x: hidden;
          padding-bottom: 36px;
          display: flex;
          flex-direction: column;
          background: white;

          &.failed {
            .content{
              .question-title{
                .number {
                  position: relative;
                  color: var(--primary);

                  &::before {
                    content: "";
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    background-image: url('https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/icons/p/pregunta_impugnada.svg');
                    top: -11px;
                    left: -56px;
                  }
                }
              }

              .question-responses {
                .ml_input_wrap {
                  &.corrected {
                    &-correct {
                      position: relative;
                      z-index: 0;

                      input {
                        opacity: 0;
                        visibility: hidden;
                      }

                      &::before {
                        content: '';
                        position: absolute;
                        inset: -7px;
                        // background: hsl(156, 87%, 77%, 0.3) 0% 0% no-repeat padding-box;
                        background: var(--success3) 0% 0% no-repeat padding-box;
                        padding: 10px;
                        border-radius: 20px;
                        z-index: -1;
                      }

                      &::after{
                        color: var(--success);
                        content: "\e8b1";
                        background: radial-gradient(circle,white 50%, var(--success3) 50%);
                        position: absolute;
                        top: -3px;
                        left: 2px;
                        font-size: 22px;
                        font-family: "fontello";
                      }
                    }

                    &-failed {
                      position: relative;
                      z-index: 0;
                      color: var(--accent);

                      input {
                        opacity: 0;
                        visibility: hidden;
                      }

                      &::after{
                        color: var(--accent);
                        content: "\e8af";
                        background-color: white;
                        position: absolute;
                        top: -3px;
                        left: 2px;
                        font-size: 22px;
                        font-family: "fontello";
                      }
                    }
                  }
                }
              }

            }


            .failed-correction-block {
              padding: 0 16%;

              .failed {
                &__title {
                  font-size: 20px;
                  text-transform: uppercase;
                  font-weight: 600;
                  color: var(--accent);
                }

                &__breadcrumb {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin: spacing(2) 0;

                  .breadcrumb__text {
                    font-size: 12px;
                    color: color(text, subtitle);

                    &--active {
                      font-weight: bold;
                    }
                  }
                  img {
                    max-width: 14px;
                    transform: rotate(-90deg);
                  }
                }
              }

              .watch-scheme {
                display: flex;
                align-items: center;
                font-size: 11px;
                color: color(text, subtitle);

                img {
                  max-width: 20px;
                }
              }

              h5 {
                font-size: 20px;
                font-weight: 600;
                color: color(text);

                &.references__title {
                  margin-bottom: spacing(2);
                }

              }
              > p {
                color: #01393A99;
                font-size: 16px;
              }

              .references-list {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: spacing(3);
                color: var(--primary);
              }
            }

            .bottom-content {
              .right {
                display: none;
              }
            }
          }

          .top-buttons{
            display: flex;
            justify-content: space-between;
            padding: 1.5% 3%;

            > div {
              max-width: toRem(86);
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              span {
                font-size: 16px;
                color: color(text, subtitle);
              }
            }

            img {
              max-width: toRem(30);
            }




          }

          .content{
            padding: 5% 16%;
            .question-title{
              .number {
                font-size: 24px;
                font-weight: 600;
                letter-spacing: 0px;
                color: var(--primary);
              }
              .title {
                font-size: 24px;
                font-weight: 600;
                letter-spacing: 0px;
                color: color(text);
              }
            }

            .question-responses {
              display: flex;
              flex-direction: column;
              row-gap: 32px;
              margin-top: 36px;
              position: relative;

              .hide-question-responses {
                position: absolute;
                top: -34px;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-size: 12px;
                width: 130px;

                img {
                  max-width: toRem(30);
                  transform: rotate(180deg);
                }
              }
            }

          }

          .bottom-content {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            padding: 0 28px;
            margin-top: auto;

            .left {
              color: color(text, subtitle);
              opacity: .7;
              text-align: left;
              font-size: 14px;
            }

            .right{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 280px;

              .ml_btn-primary.accent{
                background-color: #ED4ADD;
                border: 2px solid #ED4ADD;

                &:hover {
                  border: 0px;
                  background-color: #ffffff;
                  border: 2px solid #ffffff;
                  color: #ED4ADD;
                  box-shadow: 0 0.2rem 0.9rem 0.1rem rgb(0 0 0 / 9%);
                  -webkit-box-shadow: 0 0.2rem 0.9rem rgb(0 0 0 / 9%);
                  -moz-box-shadow: 0 0.2rem 0.9rem rgba(0, 0, 0, 0.09);
                  -webkit-appearance: none;
                  font-weight: 600;
                }
              }
            }
          }
        }


        .bottom-section {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .tests-right-side-wrapper {
        width: 280px;
        z-index: 1;

        .timer-box {
          padding: 12px 24px;
          border-radius: 20px;
          position: relative;
          width: 260px;
          @apply shadow-ml;

          .collapse-icon {
            position: absolute;
            top: 6px;
            right: 5px;
            max-width: 40px;
            transform: rotate(180deg);
          }

          .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: -8px;
            margin-bottom: 5px;
            cursor: pointer;
            .ml-icon-reloj{ font-size: 30px; }
            .collapse-icon {
              position: absolute;
              top: 6px;
              right: 5px;
              font-size: 40px;
              transform: rotate(0deg);
              transform-origin: center;
              transition: all .3s linear;
            }
            * { pointer-events: none; }
          }

          .content {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            height: 45px;
            overflow: hidden;
            transition: all .3s ease-in-out;

            .timer {
              font-size: 40px;
              font-weight: 600;
              padding: 0px 10px 0px 0px;
            }

            .control-btn {
              display: flex;
              flex-direction: column;
              font-size: 12px;
              cursor: pointer;
              justify-content: center;
              align-items: center;
              padding: 0px 10px;
              margin-top: -3px;
              .ml-icon-pause {
                font-size: 20px;
                transform: scale(2.2);
                margin: 0px;
                font-weight: bolder;
              }
              .ml-icon-play {
                font-size: 20px;
                transform: scale(1.2);
                margin: 0px;
                font-weight: bolder;
              }
              * { pointer-events: none; }
            }
          }

          &.collapse{
            .header{
              .collapse-icon{
                position: absolute;
                top: 6px;
                right: 5px;
                transform: rotate(-90deg);
              }
            }
            .content{
              height: 0;
            }
          }
        }

        .responses-title {
          font-size: 18px;
          font-weight: 600;
          text-align: center;
          margin: 20px 0;
        }

        .vertical-questions-pointer {
          max-height: calc(100% - 200px);
          position: relative;
          overflow-y: auto;
          overflow-x: hidden;
          display: flex;
          flex-direction: column;
          row-gap: 7px;

          &::before {
            position: absolute;
            content: "";
            z-index: -1;
            width: 10px;
            height: 100%;
            background:var(--primary5);
            left: 50%;
            transform: translateX(-50%);
          }

          .question-pointer {
            display: inline-grid;
            grid-template-columns: repeat(3, 1fr);
            z-index: 1;
            width: 81px;
            column-gap: 10px;
            place-items: center;
            margin: 0 auto;
            font-size: 12px;

            &.active {
              .dot {
                transform: scale(1.4);
                @apply bg-secondary;
              }

              .question-num {
                font-size: 18px;
                @apply text-secondary;
                @apply font-semibold;
              }
            }

            .status-check {
              transform: scale(0.7);
              pointer-events: none;

              input {
                margin: 0;
              }
            }

            .dot {
              width: 10px;
              height: 10px;
              background: var(--primary3);
              border-radius: 50%;
            }
          }
        }

      }
    }

    .bg-bottom {
      @apply bg-secondary5; 
      left: 25%;
      bottom: -704px;
      border-radius: 30%;
      width: 130rem;
      height: 80rem;
      transform: rotate(14deg);
      position: fixed;
      z-index: -5;
    }
  }
}

