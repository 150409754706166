%modal_card {
  background-color: white;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.ml-modal{
  background-color: #01393a96;
  position: fixed;
  height: 100vh;
  z-index: 503;
  width: 100vw;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .ml_close{
    background-image: url('https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/icons/b/cerrar.svg');
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: 200%;
    background-position:50% 50%;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .ml-modal-content-wrap{
    padding: 10px 60px;

    &.less-padding{ padding: 10px; }

    .ml_btns-wrap{
      text-align: center;
      margin: auto;
      div{
        display: inline-block;
        padding-right: 10px;
      }

      .ml_btn-wrap{
        display: inline-block;
        padding: 10px;
      }
    }
  }

  .ml_close:hover{
    background-image: url('https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/icons/b/cerrar.svg');
  }

  .ml-modal-card-sm{
    @extend %modal_card;
    min-height: 10%;
    max-height: 80%;
    width: 45%;
    min-width: 300px;
  }

  .ml-modal-card-md{
    @extend %modal_card;
    min-height: 30%;
    max-height: 90%;
    width: 45%;
    min-width: 300px;
  }

  .ml-modal-card-lg{
    min-height: 40%;
    max-height: 80%;
    width: 70%;
    min-width: 300px;
    @extend %modal_card;
  }
}

.ml_loading{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  background: #fff;
  z-index: 100;
  opacity: 1;
  transition: all .4s ease-in-out;
  &.remove_loading{ opacity: 0; }
  &.overall{ z-index: 150; }
}

.ml_toast{
  visibility: hidden;
  min-width: 200px;
  max-width: 450px;
  border-radius: 10px;
  padding: 16px;
  position: fixed;
  z-index: 10;
  right: 30px;
  top: 30px;
  color: $phgrey;
  @include shadow;
  transform: translateY(-20vh);
  transition: all .5s ease-out;
  &.success{
    background-color: var(--success5);
  }
  &.warning{
    background-color: var(--warning5);
  }
  &.error{
    background-color: var(--accent5);
  }
  &.info{
    background-color: var(--primary5);
  }
  &.white{
    background-color: white;
  }
  &.show_toast {
    visibility: visible;
    transform: translateY(0);
  }
}

