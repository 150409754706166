@use "../../styles" as *;

.crf_flash_link{
  opacity: 0;
  transition: opacity .4s ease-in-out;
  box-sizing: border-box;
  padding: 1.5rem;
  .primary{
      background-color: var(--primary);
  }
  &.ml_display{ opacity: 1; }
  &.display{ opacity: 1; }
  .main_content{
      padding: 0;
      @include bpFrom(tablet){
          padding: 0 20%;
      }
  }
  
  .left_side{
      padding: 20px 5px;
      @include bpFrom(tablet){
          padding: 50px 10%;
      }
      .logo{
          img{
              width: 80%;
              border-radius: 24px;
              @include bpFrom(tablet){
                  width: 60%;
              }
          }
      }
      .data-content{
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 20px 0;
          padding: 0px;
          .title{
            font-size: 1.1em;
            color: $dark;
          }
          @include bpFrom(tablet){
              margin: 0px;
              padding: 20px 25px;
              .title{
                  font-size: 1.3em;
                  color: $dark;
              }
          }
      }
      .ml-icon-flecha_desplegar{
          font-weight: bolder;
          transform: scale(2) rotate(-90deg);
      }
  }
  .flc_theme{
    padding: 20px 5px;
    @include bpFrom(tablet){
        padding: 50px 10%;
    }
    .colors{
        .ml_square{
            width: 100%;
            height: 0px;
            border-radius: 5%;
            padding-bottom: 100%;
        }
        span{
            font-size: 10px;
            @include bpFrom(tablet){
                font-size: 12px;
            }
        }
    }
  }

  .ml_link{
      width: fit-content;
      font-size: 4rem;
      position: absolute;
      top: 80vh;
      margin: 0px auto;
      left: 0px;
      right: 0px;
      .ml_btn-link-b:hover span{
          top: -1em;
          height: 30px;
      }
  }
}

