@use "../../styles" as *;


.ml_my_courses_wrap{
  min-width: 70%;
  @apply max-w-9xl flex flex-col pt-16 mx-auto;
  
  .ml_btn-wrap.group{
    .ml_btn-link-p,.ml_btn-link-y{
      span.link-line{
        top: unset;
        @apply max-w-[105%] absolute bottom-0;
      }
    }
  }

  .shadow-ml {
    background: white;
  }

  .bg-top {
    width: 900px;
    height: 700px;
    border-radius: 200px;
    position: fixed;
    top: -500px;
    left: 0;
    z-index: -5;
    transform: rotate(12deg);
    @apply bg-primary4;
  }

  .bg-right {
    width: 300px;
    height: 300px;
    border-radius: 100px;
    position: fixed;
    top: 70%;
    right: -40px;
    z-index: -5;
    transform: rotate(-17deg);
    @apply bg-warning4;
  }
}




