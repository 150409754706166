@import 'https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/guidelines/css/fontello-codes.css';

@font-face {
    font-family: 'fontello';
    src: url('https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/guidelines/fontello.eot?25412964');
    src: url('https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/guidelines/fontello.eot?25412964');
    src: url('https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/guidelines/fontello.eot?25412964#iefix') format('embedded-opentype'),
    url('https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/guidelines/fontello.woff?25412964') format('woff'),
    url('https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/guidelines/fontello.ttf?25412964') format('truetype'),
    url('https://s3.eu-west-1.amazonaws.com/static.meludus.com/global/guidelines/fontello.svg?25412964#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}

.ml-icon {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-size: 24px;
    speak: never;
    text-decoration: inherit;
    margin: 0 10px;
    text-align: center;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.ml-icon-xs{
        font-size: 20px;
        width: 20px;
        height: 20px;
    }
    &.ml-icon-sm{
        font-size: 30px;
        width: 30px;
        height: 30px;
    }
    &.ml-icon-md{
        font-size: 40px;
        width: 40px;
        height: 40px;
    }
    &.ml-icon-lg{
        font-size: 50px;
        width: 50px;
        height: 50px;
    }
    &.ml-icon-xl{
        font-size: 60px;
        width: 60px;
        height: 60px;
    }
}

