/* COLORS */
$dark:#0A2327;
$ldark:#01393A;
$white:#ffffff;
$grey: #707070;
$lgrey: #cccccc;
$inputwhite:rgba(255, 255, 255, 0.8);
$dgrey:#416b6b;
$phgrey:#809c9c;

//  SECONDARY COLORS
$dark3: #155B57;
$dark4: #2B7771;
$dark5: #479991;
$dark6: #4EC6C3;

/* SOMBRAS */
@mixin shadow {
    box-shadow: 0 0.2rem 0.9rem 0.1rem rgba(0, 0, 0, .09);
    -webkit-box-shadow: 0 0.2rem 0.9rem rgba(0, 0, 0, .09);
    -moz-box-shadow: 0 .2rem .9rem rgba(0, 0, 0, .09);
    -webkit-appearance: none;
}


