@use "sass:math";

@mixin getMargins($size) {
   margin: math.div($size, 4);
   font-size:$size;
   width: $size;
   height: $size;
}

%icon{
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
   text-decoration: inherit;
   text-align: center;
   text-transform: none;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   font-family: "fontello";
}
.icon-1{
   @extend %icon;
   $fontSize:1.5em;
   @include getMargins($fontSize);
}
.icon-2{
   @extend %icon;
   $fontSize:1.25em;
   @include getMargins($fontSize);
}
.icon-3{
   @extend %icon;
   $fontSize:1em;
   @include getMargins($fontSize);
}
