@import './../vars/styles.scss';
@import './typography.scss';

/* GUIDELINE STYLES*/
:root{
    --primary:#38E7ED;
    --lprimary:#7DF7FB;
    --primary2: #AFF9F7;
    --primary3: #CDF7F4;
    --primary4: #D9F9F9;
    --primary5: #F0FFFF;

    --secondary:#8070E8;
    --lsecondary:#807CE8;
    --secondary2: #A3A3EF;
    --secondary3: #B6B6F2;
    --secondary4: #D0D0F4;
    --secondary5: #E5E5F9;

    --accent:#ED4ADD;
    --laccent:#F683EA;
    --accent2: #F4A2F0;
    --accent3: #F0B6F2;
    --accent4: #F2CEF4;
    --accent5: #F9E4F8;

    --warning:#E8ED27;
    --lwarning:#F4F84F;
    --warning2: #F9F97D;
    --warning3: #F4F0A2;
    --warning4: #F4F3C1;
    --warning5: #F9F9D7;

    --success:#90F7CE;
    --lsuccess:#ABF5DB;
    --success2: #BCF7E1;
    --success3: #CBF7E6;
    --success4: #DCF9EC;
    --success5: #EFF9F5;
}

body {
    font-family: 'Spartan', sans-serif;
    font-size: 16px;
}

div, html, body, p {
    padding: 0px;
    margin: 0px;
    border: 0px;
}

.ml_block {
    display: block;
}

.ml_inline {
    display: inline-block;
}

.ml_primary, .bk-primary {
    color: var(--primary);
}

.ml_l-primary {
    color: var(--lprimary);
}

.ml_accent {
    color: var(--accent);
}

.ml_l-accent {
    color: var(--laccent);
}

.ml_secondary {
    color: var(--secondary);
}

.ml_l-secondary {
    color: var(--lsecondary);
}

.ml_warning {
    color: var(--warning);
}

.ml_l-warning {
    color: var(--lwarning);
}

.ml_success {
    color: var(--success);
}

.ml_l-success {
    color: var(--lsuccess);
}

.ml_dark {
    color: $dark;
}

.ml_l-dark {
    color: $ldark;
}

.ml_white {
    color: $white;
}

.ml_grey {
    color: $grey;
}

.ml_l-grey {
    color: $lgrey;
}

.ml_d-grey {
    color: $dgrey;
}

.ml_input-primary {
    color: var(--primary5);
}

.ml_input-white {
    color: $inputwhite;
}

.ml_ph-grey {
    color: $phgrey;
}

.ml_accent-2 {
    color: var(--accent2);
}

.ml_primary-3 {
    color: var(--primary3);
}

// secondary
.ml_primary-2 {
    color: var(--primary2);
}

.ml_primary-3 {
    color: var(--primary3);
}

.ml_primary-4 {
    color: var(--primary4);
}

.ml_primary-5 {
    color: var(--primary5);
}

.ml_secondary-2 {
    color: var(--secondary2);
}

.ml_secondary-3 {
    color: var(--secondary3);
}

.ml_secondary-4 {
    color: var(--secondary4);
}

.ml_secondary-5 {
    color: var(--secondary5);
}

.ml_accent-2 {
    color: var(--accent2);
}

.ml_accent-3 {
    color: var(--accent3);
}

.ml_accent-4 {
    color: var(--accent4);
}

.ml_accent-5 {
    color: var(--accent5);
}

.ml_warning-2 {
    color: var(--warning2);
}

.ml_warning-3 {
    color: var(--warning3);
}

.ml_warning-4 {
    color: var(--warning4);
}

.ml_warning-5 {
    color: var(--warning5);
}

.ml_success-2 {
    color: var(--success2);
}

.ml_success-3 {
    color: var(--success3);
}

.ml_success-4 {
    color: var(--success4);
}

.ml_success-5 {
    color: var(--success5);
}

.ml_dark-3 {
    color: $dark3;
}

.ml_dark-4 {
    color: $dark4;
}

.ml_dark-5 {
    color: $dark5;
}

.ml_dark-6 {
    color: $dark6;
}

// -----------BACKGROUNDS-----------
.ml_bk-primary {
    background-color: var(--primary);
}

.ml_bk-l-primary {
    background-color: var(--lprimary);
}

.ml_bk-accent {
    background-color: var(--accent);
}

.ml_bk-accent-2 {
    background-color: var(--accent2);
}

.ml_bk-primary-3 {
    background-color: var(--primary3);
}

.ml_bk-l-accent {
    background-color: var(--laccent);
}

.ml_bk-secondary {
    background-color: var(--secondary);
}

.ml_bk-l-secondary {
    background-color: var(--lsecondary);
}

.ml_bk-warning {
    background-color: var(--warning);
}

.ml_bk-l-warning {
    background-color: var(--lwarning);
}

.ml_bk-success {
    background-color: var(--success);
}

.ml_bk-l-success {
    background-color: var(--lsuccess);
}

.ml_bk-dark {
    background-color: $dark;
}

.ml_bk-l-dark {
    background-color: $ldark;
}

.ml_bk-white {
    background-color: #ffff;
}

.ml_bk-grey {
    background-color: $grey;
}

.ml_bk-l-grey {
    background-color: $lgrey;
}

.ml_bk-input-primay {
    background-color: var(--primary5);
}

.ml_bk-input-white {
    background-color: $inputwhite;
}

.ml_bk-d-grey {
    background-color: $dgrey;
}

.ml_bk-ph-grey {
    background-color: $phgrey;
}

// secondary bk
.ml_bk-primary-2 {
    background-color: var(--primary2);
}

.ml_bk-primary-3 {
    background-color: var(--primary3);
}

.ml_bk-primary-4 {
    background-color: var(--primary4);
}

.ml_bk-primary-5 {
    background-color: var(--primary5);
}

.ml_bk-secondary-2 {
    background-color: var(--secondary2);
}

.ml_bk-secondary-3 {
    background-color: var(--secondary3);
}

.ml_bk-secondary-4 {
    background-color: var(--secondary4);
}

.ml_bk-secondary-5 {
    background-color: var(--secondary5);
}

.ml_bk-accent-2 {
    background-color: var(--accent2);
}

.ml_bk-accent-3 {
    background-color: var(--accent3);
}

.ml_bk-accent-4 {
    background-color: var(--accent4);
}

.ml_bk-accent-5 {
    background-color: var(--accent5);
}

.ml_bk-warning-2 {
    background-color: var(--warning2);
}

.ml_bk-warning-3 {
    background-color: var(--warning3);
}

.ml_bk-warning-4 {
    background-color: var(--warning4);
}

.ml_bk-warning-5 {
    background-color: var(--warning5);
}

.ml_bk-success-2 {
    background-color: var(--success2);
}

.ml_bk-success-3 {
    background-color: var(--success3);
}

.ml_bk-success-4 {
    background-color: var(--success4);
}

.ml_bk-success-5 {
    background-color: var(--success5);
}

.ml_bk-dark-3 {
    background-color: $dark3;
}

.ml_bk-dark-4 {
    background-color: $dark4;
}

.ml_bk-dark-5 {
    background-color: $dark5;
}

.ml_bk-dark-6 {
    background-color: $dark6;
}

.ml_cls-primary {
    path, rect {
        fill: var(--primary);
    }
}

.ml_cls-l-primay {
    path, rect {
        fill: var(--lprimary);
    }
}

.ml_cls-accent {
    path, rect {
        fill: var(--accent);
    }
}

.ml_cls-l-accent {
    path, rect {
        fill: var(--laccent);
    }
}

.ml_cls-secondary {
    path, rect {
        fill: var(--secondary);
    }
}

.ml_cls-l-secondary {
    path, rect {
        fill: var(--lsecondary);
    }
}

.ml_cls-warning {
    path, rect {
        fill: var(--warning);
    }
}

.ml_cls-l-warning {
    path, rect {
        fill: var(--lwarning);
    }
}

.ml_cls-success {
    path, rect {
        fill: var(--success);
    }
}

.ml_cls-l-success {
    path, rect {
        fill: var(--lsuccess);
    }
}

.ml_cls-dark {
    path, rect {
        fill: $dark;
    }
}

.ml_cls-l-dark {
    path, rect {
        fill: $ldark;
    }
}

.ml_cls-white {
    path, rect {
        fill: $white;
    }
}

// secondary
.ml_cls-primary-2 {
    path, rect {
        fill: var(--primary2);
    }
}

.ml_cls-primary-3 {
    path, rect {
        fill: var(--primary3);
    }
}

.ml_cls-primary-4 {
    path, rect {
        fill: var(--primary4);
    }
}

.ml_cls-primary-5 {
    path, rect {
        fill: var(--primary5);
    }
}

.ml_cls-secondary-2 {
    path, rect {
        fill: var(--secondary2);
    }
}

.ml_cls-secondary-3 {
    path, rect {
        fill: var(--secondary3);
    }
}

.ml_cls-secondary-4 {
    path, rect {
        fill: var(--secondary4);
    }
}

.ml_cls-secondary-5 {
    path, rect {
        fill: var(--secondary5);
    }
}

.ml_cls-accent-2 {
    path, rect {
        fill: var(--accent2);
    }
}

.ml_cls-accent-3 {
    path, rect {
        fill: var(--accent3);
    }
}

.ml_cls-accent-4 {
    path, rect {
        fill: var(--accent4);
    }
}

.ml_cls-accent-5 {
    path, rect {
        fill: var(--accent5);
    }
}

.ml_cls-warning-2 {
    path, rect {
        fill: var(--warning2);
    }
}

.ml_cls-warning-3 {
    path, rect {
        fill: var(--warning3);
    }
}

.ml_cls-warning-4 {
    path, rect {
        fill: var(--warning4);
    }
}

.ml_cls-warning-5 {
    path, rect {
        fill: var(--warning5);
    }
}

.ml_cls-success-2 {
    path, rect {
        fill: var(--success2);
    }
}

.ml_cls-success-3 {
    path, rect {
        fill: var(--success3);
    }
}

.ml_cls-success-4 {
    path, rect {
        fill: var(--success4);
    }
}

.ml_cls-success-5 {
    path, rect {
        fill: var(--success5);
    }
}

.ml_cls-dark-3 {
    path, rect {
        fill: $dark3;
    }
}

.ml_cls-dark-4 {
    path, rect {
        fill: $dark4;
    }
}

.ml_cls-dark-5 {
    path, rect {
        fill: $dark5;
    }
}

.ml_cls-dark-6 {
    path, rect {
        fill: $dark6;
    }
}


/* TYPOGRAPHY */

.ml_headline {
    font-family: 'Spartan', sans-serif;
    font-size: 6rem;
    font-weight: 600;
    line-height: 1.07;
    margin-bottom: 1.3em;
    margin-top: 1.3em;
}

.ml_headline-2 {
    font-family: 'Spartan', sans-serif;
    font-size: 3.2em;
    font-weight: 600;
    line-height: 1.22;
    margin-bottom: 1.2em;
    margin-top: 1.2em;
}

.ml_headline-3 {
    font-family: 'Spartan', sans-serif;
    font-size: 2.25em;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    margin-bottom: 1em;
    margin-top: 1em;
}

.ml_headline-4 {
    font-family: 'Spartan', sans-serif;
    font-size: 1.75em;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    margin-bottom: 10px;
    margin-top: 0em;
}

.ml_paragraph-h {
    font-family: 'Spartan', sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: .8em;
    margin-top: .8em;
}

.ml_card-h {
    font-family: 'Spartan', sans-serif;
    font-size: 1.12rem;
    font-weight: 800;
    line-height: 1.5;
}

.ml_paragraph {
    font-family: 'Spartan', sans-serif;
    font-size: 1em;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
}

.ml_cathegory-text {
    font-family: 'Spartan', sans-serif;
    font-size: .68em;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
}

.ml_legal {
    font-family: 'Spartan', sans-serif;
    font-size: .8em;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
}

.ml_tx_error {
    font-family: 'Spartan', sans-serif;
    font-size: .7em;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    color: var(--accent);
    padding-top: 15px;
    padding-bottom: 15px;
}

.ml_strong {
    font-weight: 700;
}

.ml_super-strong {
    font-weight: 900;
}

.ml_text-link {
    text-decoration: underline;
    cursor: pointer;
}

.ml_link {
    text-decoration: none;
    cursor: pointer;
    color: var(--primary);
    font-weight: 600;
}

.ml_link-w {
    text-decoration: none;
    cursor: pointer;
    color: $white;
}


/* MARGINS */

.ml_m-t-10 {
    margin-top: 10px;
}

.ml_m-b-10 {
    margin-bottom: 10px;
}

.ml_m-r-10 {
    margin-right: 10px;
}

.ml_m-l-10 {
    margin-left: 10px;
}

.ml_m-t-20 {
    margin-top: 20px;
}

.ml_m-b-20 {
    margin-bottom: 20px;
}

.ml_m-l-20 {
    margin-left: 20px;
}

.ml_m-r-20 {
    margin-right: 20px;
}

.ml_m-t-24 {
    margin-top: 24px;
}

.ml_m-b-24 {
    margin-bottom: 24px;
}

.ml_m-b-30 {
    margin-bottom: 30px;
}

.ml_m-t-30 {
    margin-top: 30px;
}

.ml_m-b-40 {
    margin-bottom: 40px;
}

.ml_m-t-40 {
    margin-top: 40px;
}

.ml_m-t-60 {
    margin-top: 60px;
}

.ml_m-b-60 {
    margin-bottom: 60px;
}

.ml_m-t-100 {
    margin-top: 100px;
}

.ml_m-b-100 {
    margin-bottom: 100px;
}

.ml_m-auto {
    margin: auto;
}


/* PADDINGS */

.ml_p-t-10 {
    padding-top: 10px;
}

.ml_p-b-10 {
    padding-bottom: 10px;
}

.ml_p-r-10 {
    padding-right: 10px;
}

.ml_p-t-10 {
    padding-top: 10px;
}

.ml_p-t-20 {
    padding-top: 20px;
}

.ml_p-b-20 {
    padding-bottom: 20px;
}


/* WIDTHS */

.ml_w-100 {
    width: 100%;
}

.ml_w-30 {
    width: 30%;
    @media(max-width: 800px) {
        width: 100%;
    }
}


/* DISPLAYS */

.ml_d-block {
    display: block;
}

.ml_d-inline {
    display: inline;
}

.ml_d-inline-block {
    display: inline-block;
}

.ml_d-none, .hidden {
    display: none;
}

.ml_d-flex {
    display: flex;
}

.ml_d-flex-d {
    display: flex;
    @media (max-width: 648px) {
        display: block;
    }
}


/* FLOATING */

.ml_f-left {
    float: left;
}

.ml_f-right {
    float: right;
}


/* ALIGNMENTS */

.ml_t-r {
    text-align: right;
}

.ml_t-l {
    text-align: left;
}

.ml_t-c {
    text-align: center;
}

.ml_j-content-center {
    justify-content: center;
}


/* OVERFLOWS */

.ml_overflow-y-scroll {
    overflow-y: scroll;
}


/* BUTTONS */

.ml_btn-wrap {
    /*width: fit-content;*/
    span {
        pointer-events: none;
    }
    ml-button {
        // // font: 400 0.85em spartan;
        font-weight: 700;
    }
}
%ml_btn_fontSize{
    font-size: 0.85em;
}

.ml_btn-highlight {
    @extend %ml_btn_fontSize;
    padding: 2em 4em;
    border-radius: 5rem;
    border: 0px;
    background-color: var(--secondary);
    color: $white;
    font-weight: 600;
    cursor: pointer;
    &.ml_btn-disabled {
        pointer-events: none;
        background-color: var(--secondary5);
    }
}

.ml_btn-highlight:hover {
    padding: 2em 4em;
    border-radius: 5rem;
    border: 0px;
    background-color: $white;
    color: var(--secondary);
    @include shadow;
    font-weight: 600;
}

.ml_btn-primary {
    @extend %ml_btn_fontSize;
    padding: .7em 3.2em;
    border-radius: 5rem;
    border: 0px;
    background-color: var(--secondary);
    border: 2px solid var(--secondary);
    color: $white;
    font-weight: 600;
    cursor: pointer;
    &.ml_btn-disabled {
        pointer-events: none;
        background-color: var(--secondary5);
        border: 2px solid var(--secondary5);
    }
}

.ml_btn-primary:hover {
    padding: .7em 3.2em;
    border-radius: 5rem;
    border: 0px;
    background-color: $white;
    border: 2px solid $white;
    color: var(--secondary);
    @include shadow;
    font-weight: 600;
}

.ml_btn-secondary {
    @extend %ml_btn_fontSize;
    padding: .7em 3.2em;
    border-radius: 5rem;
    border: 0px;
    background-color: var(--primary);
    border: 2px solid var(--primary);
    color: $white;
    font-weight: 600;
    cursor: pointer;
    &.ml_btn-disabled {
        pointer-events: none;
        background-color: var(--primary5);
        border: 2px solid var(--primary5);
        color: var(--primary2);
    }
}

.ml_btn-secondary:hover {
    padding: .7em 3.2em;
    border-radius: 5rem;
    border: 0px;
    background-color: $white;
    border: 2px solid $white;
    color: var(--primary);
    @include shadow;
    font-weight: 600;
}

.ml_btn-secondary-w {
    @extend %ml_btn_fontSize;
    padding: .7em 3.2em;
    border-radius: 5rem;
    border: 0px;
    background-color: $white;
    border: 2px solid $white;
    color: var(--primary);
    font-weight: 600;
    cursor: pointer;
}

.ml_btn-secondary-w:hover {
    padding: .7em 3.2em;
    border-radius: 5rem;
    border: 0px;
    background-color: var(--primary);
    border: 2px solid var(--primary);
    color: $white;
    @include shadow;
    font-weight: 600;
}

.ml_btn-terciary {
    @extend %ml_btn_fontSize;
    padding: .7em 3.2em;
    border-radius: 5rem;
    background-color: transparent;
    border: 2px solid var(--primary);
    color: var(--primary);
    font-weight: 600;
    cursor: pointer;
    &.ml_btn-disabled {
        opacity: .3;
        pointer-events: none;
    }
}

.ml_btn-terciary:hover {
    padding: .7em 3.2em;
    border-radius: 5rem;
    background-color: var(--primary);
    border: 2px solid var(--primary);
    color: $white;
    font-weight: 600;
}

.ml_btn-terciary-w {
    @extend %ml_btn_fontSize;
    padding: .7em 3.2em;
    /*width: 15.2em;
	height: 3rem;*/
    border-radius: 5rem;
    background-color: transparent;
    border: 2px solid $white;
    color: $white;
    font-weight: 600;
    cursor: pointer;
}

.ml_btn-terciary-w:hover {
    padding: .7em 3.2em;
    /*width: 15.2em;
	height: 3rem;*/
    border-radius: 5rem;
    background-color: $white;
    border: 2px solid $white;
    color: var(--primary);
    font-weight: 600;
}
.ml_btn-terciary-icon i{
    position: relative;
    top: 2.5px;
}
.ml_btn-terciary-icon:hover i{
    color: $white;
}
.ml_btn-terciary-icon {
    @extend %ml_btn_fontSize;
    padding: 0.45em;
    padding-bottom: 0.7em;
    padding-top: 0.2em;
    padding-right: 1.7em;
    border-radius: 5rem;
    background-color: transparent;
    border: 2px solid var(--primary);
    color: var(--primary);
    font-weight: 600;
    cursor: pointer;
    position: relative;
    &.ml_btn-disabled {
        pointer-events: none;
        background-color: $lgrey;
        border: 2px solid var(--primary5);
        color: $white;
    }
    .ml_cls-primary {
        width: 15px;
        vertical-align: middle;
        position: absolute;
        left: 30px;
    }
}

.ml_btn-terciary-icon:hover {
    // padding: .7em 3.2em;
    // padding-left: 55px;
    /*width: 15.2em;
    height: 3rem;*/
    border-radius: 5rem;
    background-color: var(--primary);
    border: 2px solid var(--primary);
    color: $white;
    font-weight: 600;
    position: relative;
    .ml_cls-primary {
        path,
        rect {
            fill: $white;
        }
    }
}
.ml_btn-terciary-icon-w i{
    position: relative;
    top: 2.5px;
    color: $white;
}
.ml_btn-terciary-icon-w:hover i{
    color: var(--primary);
}
.ml_btn-terciary-icon-w {
    @extend %ml_btn_fontSize;
    padding: 0.45em;
    padding-bottom: 0.7em;
    padding-top: 0.2em;
    padding-right: 1.7em;
    /*width: 15.2em;
    height: 3rem;*/
    border-radius: 5rem;
    background-color: transparent;
    border: 2px solid $white;
    color: $white;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    .ml_cls-white {
        width: 15px;
        vertical-align: middle;
        position: absolute;
        left: 30px;
    }
}

.ml_btn-terciary-icon-w:hover {
    // padding: .7em 3.2em;
    // padding-left: 55px;
    /*width: 15.2em;
    height: 3rem;*/
    border-radius: 5rem;
    background-color: $white;
    border: 2px solid $white;
    color: var(--primary);
    font-weight: 600;
    position: relative;
    .ml_cls-white {
        path,
        rect {
            fill: var(--primary);
        }
    }
}

.ml_btn-link,
.ml_btn-link-y,
.ml_btn-link-p,
.ml_btn-link-v,
.ml_btn-link-b {
    color: $ldark;
    font-weight: 800;
    @extend %ml_btn_fontSize;
    position: relative;
    cursor: pointer;
}

.ml_btn-link a,
.ml_btn-link-y a,
.ml_btn-link-p a,
.ml_btn-link-v a,
.ml_btn-link-b a {
    color: $ldark;
    text-decoration: none;
}

.ml_btn-link p,
.ml_btn-link-y p,
.ml_btn-link-p p,
.ml_btn-link-v p,
.ml_btn-link-b p {
    position: relative;
    z-index: 99;
}

.ml_btn-link span,
.ml_btn-link-y span {
    display: block;
    z-index: 1;
    position: relative;
    top: -.5em;
    opacity: .8;
    border-radius: 6px;
    background-color: var(--lwarning);
    height: 8px;
    width: 120%;
    left: -.5em;
}

.ml_btn-link-p span {
    display: block;
    z-index: 1;
    position: relative;
    top: -.5em;
    opacity: .8;
    border-radius: 6px;
    background-color: var(--laccent);
    height: 8px;
    width: 120%;
    left: -.5em;
}

.ml_btn-link-v span {
    display: block;
    z-index: 1;
    position: relative;
    top: -.5em;
    opacity: .8;
    border-radius: 6px;
    background-color: var(--lsecondary);
    height: 8px;
    width: 120%;
    left: -.5em;
}

.ml_btn-link-b span {
    display: block;
    z-index: 1;
    position: relative;
    top: -.5em;
    opacity: .8;
    border-radius: 6px;
    background-color: var(--lprimary);
    height: 8px;
    width: 120%;
    left: -.5em;
}

.ml_btn-link:hover span,
.ml_btn-link-y:hover span,
.ml_btn-link-p:hover span,
.ml_btn-link-v:hover span,
.ml_btn-link-b:hover span {
    top: -.9em;
    height: 12px;
}

.ml_btn-icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    img {
        width: 30px;
        @media (max-width: 800px) {
            width: 40px;
        }
    }
}

.ml_link-icon-h {
    font-size: 0.75em;
    color: $dgrey;
    cursor: pointer;
    height: 24px;
    .ml_l-icon-h {
        margin-right: 6px;
        svg {
            width: 18px;
            vertical-align: bottom;
        }
        display:inline;
    }
}

.ml_link-icon-h:hover {
    color: $ldark;
    .ml_link-underline {
        display: block;
        z-index: -2;
        position: relative;
        top: -.5em;
        opacity: .8;
        border-radius: 6px;
        background-color: var(--lprimary);
        height: 8px;
        width: 83%;
        left: 1.9em;
    }
}

.ml_arrow-display.active {
    transform: rotate(180deg);
}

.ml_slide-menu {
    display: flex;
    position: relative;
    border: solid 2px var(--warning);
    border-radius: 24px;
}

.ml_slide-menu-size-4 {
    /*160px * 4 buttons = 640px*/
    width: 640px;
}

.ml_slide-menu-size-3 {
    /*160px * 3 buttons = 640px*/
    width: 480px;
}

.ml_slide-menu-size-2 {
    /*160px * 2 buttons = 640px*/
    width: 320px;
}

.ml_btn-option {
    width: 150px;
    text-align: center;
    font-size: 14px;
    height: 14px;
    border-radius: 15px;
    padding: 11px 5px;
    font-weight: 600;
    color: #769b9b;
    cursor: pointer;
}

.ml_option_selected {
    color: #01393A;
    transition: color 1.2s;
}

.ml_btn-selected {
    width: 150px;
    height: 14px;
    background-color: var(--warning);
    border-radius: 24px;
    padding: 11px 5px;
    position: absolute;
    z-index: -1;
}

.ml_item-1 {
    /*160 * 0*/
    left: 0px;
    transition: left 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.ml_item-2 {
    /*160 * 1*/
    left: 160px;
    transition: left 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.ml_item-3 {
    /*160 * 2*/
    left: 320px;
    transition: left 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.ml_item-4 {
    /*160 * 3*/
    left: 480px;
    transition: left 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}


/*INPUTS*/


/* Simple Input without icon */

.ml_input {
    background-color: var(--primary5);
    border: 0px;
    border-radius: 30px;
    padding: 10px 60px 10px 30px;
}

.ml_input-w {
    background-color: $inputwhite;
    border: 0px;
    border-radius: 30px;
    padding: 10px 60px 10px 30px;
}

.ml_inp-error {
    path,
    rect {
        fill: var(--accent);
    }
    input {
        border: var(--accent) 1px solid !important;
    }
    /*border: var(--accent) 1px solid !important;*/
}

.ml_label {
    text-align: left;
}

textarea:focus,
input:focus {
    /* Remove primay line box on focus */
    outline: none;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $phgrey;
    font-family: "Spartan", sans-serif;
}

input:focus::-webkit-input {
    color: red;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: $phgrey;
    font-family: "Spartan", sans-serif;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: $phgrey;
    font-family: "Spartan", sans-serif;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: $phgrey;
    font-family: "Spartan", sans-serif;
}


/* Input with icon,number and litle description */

.ml-question-with-number{
    display: flex;
    flex-direction: column;
    gap: 5px;
    i { margin: 0px; }
    > :nth-child(2) {
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

/* Checkbox */

.ml_input-cb-wrap {
    color: $grey;
    font-size: 0.8rem;
    font-weight: 500;
    input {
        margin-right: 10px;
    }
    label {
        padding-top: 3px;
    }
}

.ml_input-cb {
    -webkit-appearance: none;
    background-color: var(--primary5);
    border: 1px solid var(--primary5);
    border-radius: 4px;
    display: inline-block;
    position: relative;
    padding: 8px;
    vertical-align: top;
    margin-top: 0px;
    &ml_input-cb-line {
        background-color: var(--primary);
        border: 1px solid var(--primary5);
    }
}

.ml_input-cb-line:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 3px;
    width: 11px;
    height: 5px;
    background-color: var(--primary);
    border-radius: 4px;
}

.ml_input-cb:checked {
    background-color: var(--primary);
    border: 1px solid var(--primary);
}

.ml_input-cb:checked:after {
    content: '';
    position: absolute;
    top: 6px;
    left: 4px;
    width: 12.6px;
    height: 4px;
    background-color: white;
    border-radius: 4px;
    transform: rotate(-45deg);
}

.ml_input-cb:checked:before {
    content: '';
    position: absolute;
    top: 7px;
    left: 2px;
    width: 8px;
    height: 4px;
    background-color: white;
    border-radius: 2px;
    transform: rotate(45deg);
}

.ml_input-cb-wrap-light {
    color: $grey;
    font-size: 0.8rem;
    font-weight: 500;
    input {
        margin-right: 10px;
    }
    label {
        padding-top: 3px;
    }
}

.ml_input-cb-light {
    -webkit-appearance: none;
    background-color: var(--primary5);
    border: 1px solid var(--primary5);
    border-radius: 4px;
    display: inline-block;
    position: relative;
    padding: 8px;
    vertical-align: top;
    margin-top: 0px;
}

.ml_input-cb-wrap-light {
    input {
        background-color: $inputwhite;
        border: 1px solid $inputwhite;
    }
}

.ml_input-cb-light:checked {
    background-color: $white;
    border: 1px solid $white;
}

.ml_input-cb-light:checked:after {
    content: '';
    position: absolute;
    top: 6px;
    left: 4px;
    width: 12.6px;
    height: 4px;
    background-color: var(--primary);
    border-radius: 4px;
    transform: rotate(-45deg);
}

.ml_input-cb-light:checked:before {
    content: '';
    position: absolute;
    top: 7px;
    left: 2px;
    width: 8px;
    height: 4px;
    background-color: var(--primary);
    border-radius: 2px;
    transform: rotate(45deg);
}


/* Checkbox secondary*/

.ml_input-cb-secondary {
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid var(--secondary);
    border-radius: 4px;
    display: inline-block;
    position: relative;
    padding: 8px;
    vertical-align: top;
    margin-top: 0px;
}

.ml_input-cb-secondary:checked {
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
}

.ml_input-cb-secondary:checked:after {
    content: '';
    position: absolute;
    top: 6px;
    left: 4px;
    width: 12.6px;
    height: 4px;
    background-color: white;
    border-radius: 4px;
    transform: rotate(-45deg);
}

.ml_input-cb-secondary:checked:before {
    content: '';
    position: absolute;
    top: 7px;
    left: 2px;
    width: 8px;
    height: 4px;
    background-color: white;
    border-radius: 2px;
    transform: rotate(45deg);
}

/* Radiobutton */

.ml_input-rb-label {
    display: block;
    position: relative;
    padding-left: 30px;
    padding-top: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ml_input-rb-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.ml_input-rb-checkmark {
    position: absolute;
    top: -3px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}

.ml_input-rb-checkmark-w {
    position: absolute;
    top: -3px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}
.ml_input-rb-checkmark-secondary {
    position: absolute;
    top: -3px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}
.ml_input-rb-checkmark-dark {
    position: absolute;
    top: -3px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
}


/* On mouse-over, add a grey background color */

.ml_input-rb-label:hover input~.ml_input-rb-checkmark {
    background-color: var(--lprimary);
}

.ml_input-rb-label:hover input~.ml_input-rb-checkmark-w {
    background-color: $white;
}
.ml_input-rb-label:hover input~.ml_input-rb-checkmark-secondary {
    background-color: var(--secondary2);
}
.ml_input-rb-label:hover input~.ml_input-rb-checkmark-dark {
    background-color: $dark5;
}


/* When the radio button is checked, add a primay background */

.ml_input-rb-label input:checked~.ml_input-rb-checkmark {
    background-color: var(--primary);
}

.ml_input-rb-label input:checked~.ml_input-rb-checkmark-w {
    background-color: $white;
}
.ml_input-rb-label input:checked~.ml_input-rb-checkmark-secondary {
    background-color: var(--secondary);
}
.ml_input-rb-label input:checked~.ml_input-rb-checkmark-dark {
    background-color: $dark3;
}


/* Create the indicator (the dot/circle - hidden when not checked) */

.ml_input-rb-checkmark:after,
.ml_input-rb-checkmark-w:after,
.ml_input-rb-checkmark-secondary:after,
.ml_input-rb-checkmark-dark:after  {
    content: "";
    position: absolute;
    display: none;
}


/* Show the indicator (dot/circle) when checked */

.ml_input-rb-label input:checked~.ml_input-rb-checkmark:after,
.ml_input-rb-label input:checked~.ml_input-rb-checkmark-w:after,
.ml_input-rb-label input:checked~.ml_input-rb-checkmark-secondary:after,
.ml_input-rb-label input:checked~.ml_input-rb-checkmark-dark:after {
    display: block;
}


/* Style the indicator (dot/circle) */

.ml_input-rb-label .ml_input-rb-checkmark:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.ml_input-rb-label .ml_input-rb-checkmark-w:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--primary);
}
.ml_input-rb-label .ml_input-rb-checkmark-secondary:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}
.ml_input-rb-label .ml_input-rb-checkmark-dark:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}


/* LISTS */

.ml_ul {
    list-style: none;
    /* Remove default bullets */
    text-align: left;
    .bullet {
        width: 10px;
        height: 10px;
        border-radius: 50px;
        display: inline-block;
        /* Needed to add space between the bullet and the text */
        margin-top: 14px;
        margin-right: 10px;
    }
}


/* COOKIES */

.ml_cookies {
    width: 100%;
    background-color: $white;
    position: fixed;
    bottom: 0;
    text-align: center;
    color: $ldark;
    font-size: 14px;
    z-index: 99;
    box-shadow: 0 2px 20px 0 rgb(231, 231, 231);
    p {
        max-width: 80%;
        text-align: center;
        margin: auto;
        margin-top: 40px;
    }
    .ml_btn-cookies {
        display: block;
        width: max-content;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 40px;
    }
}

//  code font
.code {
    font-family: monospace;
}

/* SNACKBAR */
.ml_snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: $lgrey;
  color: $ldark;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

.ml_snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.ml_disabled-clic{
    pointer-events: none;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

//ml-pass-icon

.ml-pass-icon{
    position: relative;
    ml-password{
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
    }
    .icon-1{
        margin: 0px;
        display: inline-block;
        position: absolute;
        top: 3px;
        &::before{
            line-height: 30px;
        }
    }
    &.right{
        ml-password{ padding-right: 40px; }
        .icon-1{ right: 5px; }
    }
    &.left{
        ml-password{ padding-left: 40px; }
        .icon-1{ left: 5px; }
    }
}

//ml-input-icon

.ml-input-icon{
    position: relative;
    ml-input{
      display: inline-block;
      width: 100%;
      box-sizing: border-box;
    }
    .icon-1{
      margin: 0px;
      position: absolute;
      top: 3px;
      &::before{
        line-height: 30px;
      }
    }
    &.right{
      ml-input{ padding-right: 40px; }
      .icon-1{ right: 5px; }
    }
    &.left{
      ml-input{ padding-left: 40px; }
      .icon-1{ left: 5px; }
    }
  }

