@use "../../../styles" as *;
@use '../../../components/arbol-contenido/styles.scss' as *;
@import '../../../styles/utilities/typography';


.section-create_test { 
  overflow-x: clip;

  .top-square {
    position: fixed;
    top: 200px;
    right: -120px;
    border-radius: 30%;
    background: rgba(237, 74, 221, 0.302);
    width: 200px;
    height: 200px;
    transform: rotate(335deg);
    z-index: -1;
  }

  .bottom-square {
    position: fixed;
    background: rgba(237, 74, 221, 0.302);
    left: -50px;
    bottom: -30vw;
    width: 50vw;
    aspect-ratio: 1 / 1;
    border-radius: 15vw;
    transform: rotate(-25deg);
    z-index: -1;
  }
}

.section-create_test .icon-text-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;

  i {
    font-size: 30px;
    margin: 0px;
    padding: 0px;
    width: auto;
    height: auto;    
  }

  p.icon-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
  }
}

.section-create_test .tree-content-modal {
  padding: 7px;

  h6 {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    margin: 0;
    margin-bottom: 20px;
  }

  #tree_content_wrap {
    width: 100%;
    height: 45vh;
    overflow-y: auto;
    margin-bottom: 20px;
  }

  .button-group {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    column-gap: 30px;
  }

  .button-group ml-button button {
    width: 100%;
  }
}

.section-create_test .insufficient-questions-modal {
  padding: 7px;

  h6 {
    font-size: 18px;
    line-height: 20px;
    font-weight: normal;
    margin: 0;
    margin-bottom: 25px;
    text-align: center;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 10px;
    text-align: center;
  }

  p.last-paragraph {
    margin-bottom: 30px;
  }

  .button-group {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    column-gap: 30px;
  }

  .button-group ml-button button {
    width: 100%;
  }
}

.create-test-page {
  box-sizing: border-box;
  padding: 29px 29px 180px 29px;
  width: 100%;
  max-width: 1260px;
  margin-inline: auto;
}

.create-test-page .main-title {
  margin-bottom: 20px;

  h2 {
    font-size: 22px;
    line-height: 34px;
    margin: 0px 0px 5px 0px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
    font-weight: 500;
  }
}

.create-test-page .test-name-section {
  margin-bottom: 50px;
}

.create-test-page .test-name-section .test-name-card {
  @include shadow;
  position: relative;
  box-sizing: border-box;
  padding: 50px 29px;
  border-radius: 20px;
  background-color: white;
  width: 100%;

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
    margin-bottom: 30px;
  }

  p.maxWidth {
    max-width: 500px;
  }

  .card-inputs {
    width: 100%;
    max-width: 660px;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 40px;
    column-gap: 20px;
  }

  .input-wrap .input-label {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
  }

  .input-wrap .input-label i {
    font-size: 20px;
    margin: 0px;
    padding: 0px;
    width: auto;
    height: auto;
  }

  .input-wrap .input-label p {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }

  .input-wrap ml-input {
    width: 100%;
    display: block;
  }

  .input-wrap ml-input input {
    width: 100%;
  }

  ml-ludubot2 {
    position: absolute;
    top: 80px;
    left: 800px;
    display: none;
  }
}

.create-test-page .main-container {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 40px;
  column-gap: 20px;
}

.create-test-page .main-container .more-settings-container {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 40px;
  column-gap: 20px;
  grid-template-areas: "header";

  .advance-settings {
    display: none;
  }

  &.active {
    grid-template-areas: "header"
      "duration"
      "difficulty"
      "nro-question"
      "nro-answer"
      "filter-question"
      "fault-sys"
      "validation"
      "save";
  }

  &.active .advance-settings {
    display: block;
  }

  .header-setting {
    grid-area: header;
  }

  .duration-setting {
    grid-area: duration;
  }

  .difficulty-settting {
    grid-area: difficulty;
  }

  .nro-question-setting {
    grid-area: nro-question;
  }

  .nro-answer-setting {
    grid-area: nro-answer;
  }

  .filter-question-setting {
    grid-area: filter-question;
  }

  .fault-sys-setting {
    grid-area: fault-sys;
  }

  .validation-setting {
    grid-area: validation;
  }

  .save-setting {
    grid-area: save;
  }
}

.create-test-page .main-container .setting-opt {
  h6 {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    margin: 0;
  }

  p.tree-description {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 15px;
    position: relative;
    margin-left: 15px;
  }

  p.tree-description:hover span {
    display: block;
  }

  p.tree-description span {
    display: none;
    @include shadow;
    position: absolute;
    bottom: 50px;
    left: 0px;
    background-color: white;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 20px;
  }

  p.tree-description::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: var(--primary);
    border-radius: 50%;
    top: 3px;
    left: -14px;
  }

  ml-button button {
    width: 100%;
  }
}

.create-test-page .main-container .header-setting-wrap,
.create-test-page .main-container .setting-duration,
.create-test-page .main-container .setting-difficulty,
.create-test-page .main-container .setting-nro-question,
.create-test-page .main-container .setting-nro-answer,
.create-test-page .main-container .setting-filter-question,
.create-test-page .main-container .setting-fault-sys,
.create-test-page .main-container .setting-validation,
.create-test-page .main-container .save-template-card {
  h6 {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    margin: 0;
    margin-bottom: 15px;
  }
}

.create-test-page .main-container .header-setting-wrap {
  .show-advance-setting {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    padding: 5px 20px;
    border-radius: 20px;
    border: 1px solid var(--primary);
    transition: background-color 0.3s ease;
  }

  .show-advance-setting.active {
    background-color: var(--primary);
  }

  .show-advance-setting.active span,
  .show-advance-setting.active i {
    color: white;
  }

  .show-advance-setting.active i {
    transform: rotate(180deg);
  }

  .show-advance-setting span,
  .show-advance-setting i {
    color: var(--primary);
    pointer-events: none;
  }

  .show-advance-setting span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    transition: color 0.3s ease;
  }

  .show-advance-setting i {
    font-size: 30px;
    display: block;
    margin: 0px;
    padding: 0px;
    width: auto;
    height: auto;
    transition: transform 0.3s ease, color 0.3s ease;
  }
}

.create-test-page .main-container .setting-difficulty {
  .checkbox-group {
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
  }

  .checkbox-group input {
    margin-right: 0px;
  }
}

.create-test-page .main-container .setting-nro-question {
  .input-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .input-wrap input {
    width: 100px;
  }

  .input-wrap p {
    font-size: 12px;
    margin: 0;
  }
}

.create-test-page .main-container .setting-nro-answer {
  .input-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

.create-test-page .main-container .setting-filter-question {
  .checkbox-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    row-gap: 10px;
    column-gap: 20px;
  }

  .checkbox-group input {
    margin-right: 0px;
  }
}

.create-test-page .main-container .setting-fault-sys {
  .sys-group {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
  }

  .sys-group .input-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .sys-group .input-wrap input {
    width: 100px;
  }

  .sys-group .input-wrap span {
    font-size: 12px;
    margin: 0;
  }
}

.create-test-page .main-container .setting-validation {
  .js_val_section {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 20px;
    row-gap: 20px;
  }

  .js_val_section label {
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
  }

  .js_val_section .ml_input-rb-label {
    width: fit-content;
  }
}

.create-test-page .main-container .save-template-card {
  @include shadow;
  box-sizing: border-box;
  padding: 50px 29px;
  border-radius: 20px;
  background-color: white;
  width: 100%;

  ml-input input {
    width: 100%;
  }
}

.section-create_test .main-action-footer {
  position: fixed;
  bottom: 0;
  box-shadow: 0px 0px 6px #00000015;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 60px;
  width: 100%;
  left: 0;
  background-color: white;
  box-sizing: border-box;
  padding: 10px 29px;

  .action-wrap {
    width: 100%;
    max-width: 1260px;
    margin-inline: auto;
  }

  .btn-hover {
    position: relative;
    width: fit-content;
    margin-left: auto;
  }

  .btn-hover:hover .msg-hover {
    display: block;
  }

  .btn-hover .msg-hover {
    display: none;
    position: absolute;
    top: -120px;
    left: -100%;
    background-color: white;
    box-sizing: border-box;
    padding: 15px 25px;
    width: 316px;
    border-radius: 20px;
    @include shadow;
    font-size: 14px;
    line-height: 20px;
  }

  .btn-hover .msg-hover.hidden {
    display: none!important;
  }
}


@media (min-width: 480px) {
  .create-test-page .main-container .setting-filter-question {
    .checkbox-group {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}


@media (min-width: 600px) {
  .create-test-page .main-container .setting-filter-question {
    .checkbox-group {
      display: flex;
      column-gap: 30px;
    }
  }

  .create-test-page .main-container .save-template-card {
    ml-input input {
      width: auto;
    }
  }
}


@media (min-width: 680px) {
  .section-create_test .tree-content-modal {
    width: 500px;

    .button-group {
      flex-direction: row;
      justify-content: center;
    }
  }

  .section-create_test .insufficient-questions-modal {
    width: 500px;

    .button-group {
      flex-direction: row;
      justify-content: center;
    }
  }

  .create-test-page .test-name-section .test-name-card {
    padding: 50px 40px;

    .card-inputs {
      grid-template-columns: 1fr 1fr;
    }
  }

  .create-test-page .main-container .setting-opt {
    ml-button button {
      width: auto;
    }
  }

  .create-test-page .main-container .header-setting-wrap {
    .show-advance-setting {
      width: fit-content;
    }
  }

  .create-test-page .main-container .setting-fault-sys {
    .sys-group {
      grid-template-columns: 1fr 1fr;
    }
  }

  .create-test-page .main-container .setting-validation {
    .js_val_section {
      grid-template-columns: 1fr 1fr;
    }
  }
}


@media (min-width: 720px) {
  .create-test-page .main-container {
    grid-template-columns: 320px 1fr;
  }

  .create-test-page .main-container .setting-filter-question {
    .checkbox-group {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .create-test-page .main-container .setting-fault-sys {
    .sys-group {
      grid-template-columns: 1fr;
    }
  }

  .create-test-page .main-container .setting-validation {
    .js_val_section {
      grid-template-columns: 1fr;
    }
  }

  .create-test-page .main-container .save-template-card {
    ml-input input {
      width: 100%;
    }
  }

  .create-test-page .main-container .setting-opt {
    position: sticky;
    top: 100px;
    left: 0px;
  }
}


@media (min-width: 900px) {
  .create-test-page .main-container .setting-filter-question {
    .checkbox-group {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .section-create_test .tree-content-modal {
    width: 700px;
  }
}


@media (min-width: 1000px) {
  .create-test-page .main-container .setting-validation {
    .js_val_section {
      grid-template-columns: 1fr 1fr;
    }
  }
}


@media (min-width: 1100px) {
  .create-test-page .test-name-section .test-name-card {
    ml-ludubot2 {
      display: block;
    }
  }

  .create-test-page .main-container .more-settings-container {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "header header";

    &.active {
      grid-template-areas: "header header"
        "duration difficulty"
        "nro-question nro-answer"
        "filter-question filter-question"
        "fault-sys fault-sys"
        "validation validation"
        "save save";
    }
  }

  .create-test-page .main-container .header-setting-wrap {
    .show-advance-setting:hover {
      background-color: var(--primary);
    }
  
    .show-advance-setting:hover span,
    .show-advance-setting:hover i {
      color: white;
    }
  }

  .create-test-page .main-container .setting-filter-question {
    .checkbox-group {
      display: flex;
      column-gap: 30px;
    }
  }

  .create-test-page .main-container .setting-fault-sys {
    .sys-group {
      grid-template-columns: 1fr 1fr;
    }
  }

  .create-test-page .main-container .save-template-card {
    ml-input input {
      width: auto;
    }
  }
}


@media (min-width: 1200px) {
  .create-test-page .test-name-section .test-name-card {
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .section-create_test .tree-content-modal {
    width: 800px;
  }
}


@media (min-width: 1300px) {
  .create-test-page .main-container .setting-validation {
    .js_val_section {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
