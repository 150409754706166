/* NEW TYPOGRAPHY */
.title_1, .title_2, .title_3, .title_4, .title_5, .title_6, .p_14, .p_12, .p_11, .p_10 {
    font-family: 'Spartan', sans-serif;
    &.regular{ font-weight: 400; }
}

.title_1{
    font-size:  2.25em;
    font-weight: 700;
}

.title_2{
    font-size:  1.75em;
    font-weight: 600;
}

.title_3{
    font-size:  1.5em;
    font-weight: 600;
    &.semibold{ font-weight: 600; }
    &.extrabold{ font-weight: 800; }
}

.title_4{
    font-size:  1.25em;
    font-weight: 700;
}

.title_5{
    font-size:  1.125em;
    font-weight: 700;
    &.bold{ font-weight: 700; }
    &.semibold{ font-weight: 600; }
    &.medium{ font-weight: 500; }
}

.title_6{
    font-size: 1em;
    font-weight: 700;
    &.bold{ font-weight: 700; }
    &.semibold{ font-weight: 600; }
    &.medium{ font-weight: 500; }
}

.p_14{
    font-size:  0.875em;
    font-weight: 700;
    &.bold{ font-weight: 700; }
    &.semibold{ font-weight: 600; }
    &.medium{ font-weight: 500; }
    &.extrabold{ font-weight: 800; }
}

.p_12{
    font-size:  0.75em;
    font-weight: 600;
    &.semibold{ font-weight: 600; }
}

.p_11{
    font-size:  0.688em;
    font-weight: 700;
    &.bold{ font-weight: 700; }
    &.medium{ font-weight: 500; }
}

.p_10{
    font-size:  0.625em;
    font-weight: 500;
}

.typ_js:hover{
    cursor: pointer;
    text-decoration: underline;
}

