.ml-dashboard-page{
  position: relative;
  overflow-x: clip;

  .dashboard-main-body {
    padding-top: 10px;
  }

  .main-title {
    margin-top: 20px;
    
    h2 {
      font-size: 22px;
      line-height: 34px;
      margin: 0px;
    }
  }

  .ml-avatar-dashborad{
    width: 200px;
    margin: 30px;
    background-color: var(--primary);
    border-radius: 100px;   
  }

  .ml-square-01{
    position: absolute;
    z-index: -1;

    width: 800px;
    height: 800px;
    left: 270px;
    transform: rotate(15deg);
    border-radius: 100px;
    top: -550px;

    background-color: var(--primary4);
  }

  t-dashboard{
    .hidden{
      display: none;
    }
  }

  .container_date_copy{
    width: 20rem;
  }
}

.ml-dashboard-page .slide-dashboard {
  border: 2px solid var(--warning);
  border-radius: 20px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  max-width: 370px;
  overflow: hidden;
  background: white;
  margin-inline: auto;

  .bg-slide {
    position: absolute;
    background: var(--warning);
    height: 100%;
    width: 33.33%;
    border-radius: 20px;
    z-index: 0;
    transition: left 0.5s ease;
  }

  &[data-for="sr-today"] .bg-slide {
    left: 0px;
  }

  &[data-for="sr-today"] #sr-today {
    opacity: 1;
    cursor: default;
  }

  &[data-for="sr-last-week"] .bg-slide {
    left: 33.33%;
  }

  &[data-for="sr-last-week"] #sr-last-week {
    opacity: 1;
    cursor: default;
  }

  &[data-for="sr-total"] .bg-slide {
    left: 66.66%;
  }

  &[data-for="sr-total"] #sr-total {
    opacity: 1;
    cursor: default;
  }

  .sr-option {
    text-align: center;
    padding-block: 10px;
    position: relative;
    z-index: 1;
    font-size: 12px;
    font-weight: 700;
    opacity: .5;
    transition: opacity 0.5s ease;
    cursor: pointer;
  }
}

.ml-dashboard-page .mobile-card {
  @apply shadow-ml;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  padding-block: 20px;
  min-height: 200px;

  &.card-for-avatar {
    background-color: white;
    width: 40%;
    align-items: center;
    border-radius: 25px;
    margin-right: -12%;
    z-index: 2;
  }

  &.card-for-achievements {
    align-items: flex-end;
    width: 40%;
    padding-right: 9%;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    z-index: 1;
    margin-right: -12%;
  }

  &.card-for-stat {
    background-color: var(--primary);
    width: 40%;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 15px;
    padding-right: 10%;
  }
}


.mark_bar{
  background: white;
  padding-left: 30px;
  margin-left: -30px;
  width: 590px;
  height: 60px;
  border-radius: 20px;
  z-index: 503;
  pointer-events: none;
  box-shadow: 0px 0px 6px rgba(0,0,0,0.07843);
  .editLicense{
    position: fixed;
    width: 100%;
    margin-top: -35px;
    pointer-events: none;
  }
}

.mark_estadistics{
  z-index: 503;
  position: fixed;
  pointer-events: none;

}

.mark_menuList{
  z-index: 503;
  position: fixed;
  pointer-events: none;
}


@screen md {
  .ml-dashboard-page {
    .ml-square-01 {
      width: 1000px;
      height: 1000px;
      left: -221px;
      transform: rotate(10deg);
      border-radius: 260px;
      top: -591px;
    }
  }

  .ml-dashboard-page .slide-dashboard {
    max-width: 600px;

    .sr-option {
      font-size: 16px;
    }
  }
}

@media (min-width: 800px) {
  .ml-dashboard-page {
    .main-title {
      padding-left: 40px;
    }
  }
}


//media querys menos de 1024px en css
@media (max-width: 1275px) {
  .ml-dashboard-page {
    .container_date_copy {
      width: 15rem;
    }
  }
}


@media (max-width: 1229px) {
  .ml-dashboard-page {
    .container_date_copy {
      width: 13rem;
    }
  }
}

