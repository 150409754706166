.dj-blank-for-opos {
  width: 100%;
  height: calc(100dvh - 110px);
  place-content: center;
  position: relative;
  overflow: clip;

  .dj-figure {
    position: absolute;
    height: 120vh;
    right: -70vh;
    top: 10vh;
  }
}

.dj-blank-for-opos .dj-blank-for-opos__content {
  text-align: center;
  z-index: 1;
  box-sizing: border-box;
  padding-inline: 20px;

  img.dj-logo {
    width: 130px;
    margin-bottom: 20px;
  }

  h5, h4, p {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  
  h5 {
    font-size: 16px;
    color: var(--secondary);
    font-weight: 700;
    margin-bottom: 20px;
  }

  .h4-group {
    margin-bottom: 30px;
  }

  h4 {
    font-size: 22px;
    font-weight: 600;
  }
  
  p {
    font-size: 10px;
    font-weight: 500;
  }
}

@media (min-width: 640px) {
  .dj-blank-for-opos .dj-blank-for-opos__content {
    img.dj-logo {
      width: 183px;
    }

    h5 {
      font-size: 20px;
    }

    h4 {
      font-size: 29px;
    }

    p {
      font-size: 13px;
    }
  }
}

@media (min-width: 968px) {
  .dj-blank-for-opos {
    height: calc(100dvh - 60px);
  }
}


@media (min-width: 1200px) {
  .dj-blank-for-opos .dj-blank-for-opos__content {
    img.dj-logo {
      width: 183px;
    }

    h5 {
      font-size: 20px;
    }

    h4 {
      font-size: 29px;
    }

    p {
      font-size: 13px;
    }
  }
}


@media (min-width: 1400px) and (min-height: 720px), (min-height: 1000px) {
  .dj-blank-for-opos .dj-blank-for-opos__content {
    img.dj-logo {
      width: 261px;
    }

    h5 {
      font-size: 25px;
    }

    h4 {
      font-size: 36px;
    }

    p {
      font-size: 16px;
    }
  }
}
