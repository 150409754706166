@use "../../styles" as *;

.rankings-page {
  min-height: 100dvh;
  box-sizing: border-box;
  position: relative;
  overflow: clip;

  * { box-sizing: border-box; }
}

.rankings-page div.main-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 25px;
  z-index: 1;
  width: 100%;
  max-width: 431px;
  margin-inline: auto;
  padding-block: 50px;
  padding-inline: spacing(6);

  .selector {
    grid-column: 1 / 4;
    grid-row: 1 / 2;
    margin-bottom: 40px;
  }

  .selector.block-actions {
    pointer-events: none;
  }

  .selector ml-slide {
    display: block;
    margin-inline: auto;
    width: fit-content;
  }

  .ranking-card {
    display: none;
    width: 100%;
    max-width: 137px;
    flex-direction: column;
    justify-content: flex-end;
    height: 230px;
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .ranking-card-owner {
    position: absolute;
    top: 130px;
    left: -167px;
    display: none;
    width: 137px;
    background-color: #01393A;
    border-radius: 20px;
    text-align: center;
    padding: 30px 15px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    transform: translateY(0px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .ranking-card.hide,
  .ranking-card-owner.hide,
  .ranking-list.hide {
    opacity: 0;
    transform: translateY(50px);
  }

  .ranking-list {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    width: 100%;
    grid-column: 1 / 4;
    grid-row: 2 / 3;
    transform: translateY(0px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
}


.rankings-page .slide-ranking {
  border: 2px solid var(--warning);
  border-radius: 20px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 350px;
  overflow: hidden;
  background: white;
  margin-inline: auto;

  .bg-slide {
    position: absolute;
    background: var(--warning);
    height: 100%;
    width: 50%;
    border-radius: 20px;
    z-index: 0;
    transition: left 0.5s ease;
  }

  &[data-for="sr-weekly"] .bg-slide {
    left: 0px;
  }

  &[data-for="sr-weekly"] #sr-weekly {
    opacity: 1;
    cursor: default;
  }

  &[data-for="sr-general"] .bg-slide {
    left: 50%;
  }

  &[data-for="sr-general"] #sr-general {
    opacity: 1;
    cursor: default;
  }

  .sr-option {
    text-align: center;
    padding-block: 10px;
    position: relative;
    z-index: 1;
    font-size: 16px;
    font-weight: 700;
    opacity: .5;
    transition: opacity 0.5s ease;
    cursor: pointer;
  }
}


.rankings-page div.main-container .ranking-card {
  i {
    margin: 0px 2px 0px 0px;
    font-size: 25px;
  }

  .card {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 20px;
    padding: 45px 15px 10px 15px;
    text-align: center;
    background-color: white;
  }

  .box-position {
    position: absolute;
    top: -50px;
    left: 0px;
    right: 0px;
    margin-inline: auto;
    width: 50px;
  }

  .box-position .ranking-position{
    text-align: center;
    font-size: 16px;
    font-weight: bolder;
    color: #01393A;
    margin-bottom: 5px;
  }

  .box-position .avatar {
    position: relative;
    width: 50px;
    height: 50px;
  }

  .box-position .avatar .frame {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    scale: 1.4;
    top: 7px;
    left: -1px;
  }

  .box-position .avatar .avatar-wrap {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #38E7ED;
    border-radius: 50%;
    top: 5px;
    left: 5px;
    z-index: 2;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  p.nick {
    font-size: 14px;
    font-weight: 500;
  }

  .ludus-wrap span.ludus {
    font-size: 14px;
    font-weight: bolder;
  }

  .ludus-wrap span.ludus-text {
    font-size: 8px;
    font-weight: 500;
  }

  .ranking-likes {
    color: #01393A;
  }

  .ranking-likes span {
    font-size: 12px;
    font-weight: bolder;
  }
}


.rankings-page div.main-container .ranking-card-owner {
  i {
    margin: 0px 2px 0px 0px;
    font-size: 25px;
  }

  .ranking-position {
    text-align: center;
    font-size: 16px;
    font-weight: bolder;
    color: white;
    margin-bottom: 10px;
  }

  .avatar-wrap {
    width: 60px;
    height: 60px;
    background: #38E7ED;
    border-radius: 50%;
    overflow: hidden;
    margin-inline: auto;
    margin-bottom: 10px;

    img {
      width: 100%;
    }
  }

  p.nick {
    font-size: 14px;
    font-weight: 500;
    color: white;
  }

  .ludus-wrap {
    margin-bottom: 20px;
    color: white;
  }

  .ludus-wrap span.ludus {
    font-size: 14px;
    font-weight: bolder;
  }

  .ludus-wrap span.ludus-text {
    font-size: 8px;
    font-weight: 500;
  }

  .ranking-likes {
    color: white;
  }

  .ranking-likes span {
    font-size: 12px;
    font-weight: bolder;
  }
}


.rankings-page .ludubot-ranking {
  display: none;
  position: absolute;
  top: 100px;
  right: -320px;
  width: 300px;
  transform: translateY(0px);
  transition: opacity 0.5s ease, transform 0.5s ease;

  &.hide {
    opacity: 0;
    transform: translateY(50px);
  }

  &:after {
    position: absolute;
    margin-inline: auto;
    left: 0px;
    right: 0px;
    background-color: #cccccc;
    bottom: 10%;
    content: '';
    width: 0px;
    height: 5px;
    border-radius: 50%;
    opacity: .5;
    filter: blur(4px);
    transform: translateX(4px);
    animation: shadow_bounding 2s 1s ease infinite;
  }

  @keyframes shadow_bounding {
    0% {
      width: 100px;
    }

    50% {
      width: 50px;
    }

    100% {
      width: 100px;
    }
  }
}

.rankings-page .ludubot-ranking .ludubot-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  justify-content: center;
  animation: ludo_bounding 2s 1s ease infinite;

  .msg-box {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: white;
    text-align: center;
    border-radius: 20px;
    width: 95%;
    padding: 60px 20px 30px 20px;
    box-shadow: 0 0.2rem 0.9rem 0.1rem rgba(0, 0, 0, .09);
    transform: translateY(40px);

    &:after {
      content: '▲';
      height: 10px;
      width: 10px;
      bottom: -27px;
      left: 50%;
      position: fixed;
      transform: rotate(180deg);
      font-size: 30px;
      color: white;
      text-shadow: 0px -6px 8px rgba(0, 0, 0, 0.09);
    }

    h2 {
      font-size: 28px;
      color: #38E7ED;
    }

    p {
      font-size: 16px;
    }

    img {
      position: absolute;
      top: -35px;
    }
  }

  @keyframes ludo_bounding {
    0% {
      transform: translateY(20px);
    }

    50% {
      transform: translateY(0px);
    }

    100% {
      transform: translateY(20px);
    }
  }
}


.rankings-page .ranking-list .ranking-item {
  display: flex;
  align-items: center;
  column-gap: 5px;
  background-color: white;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 15px 20px;

  i {
    margin: 0px 2px 0px 0px;
    font-size: 25px;
  }

  .ranking-position {
    font-size: 14px;
    font-weight: bolder;
    width: 35px;
    color: #01393A;
  }

  .avatar {
    position: relative;
    width: 50px;
    height: 50px;
  }

  .avatar .frame {
    display: none;
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    scale: 1.4;
    top: 7px;
    left: -1px;
  }

  .avatar .avatar-wrap {
    position: absolute;
    width: 40px;
    height: 40px;
    background: #38E7ED;
    border-radius: 50%;
    top: 5px;
    left: 5px;
    z-index: 2;
    overflow: hidden;

    img { width: 100%; }
  }

  .ranking-ludus {
    flex: 1;
    color: #01393A;
  }

  .ranking-ludus p {
    font-size: 14px;
    font-weight: 500;
  }

  .ranking-ludus span.ludus {
    font-size: 14px;
    font-weight: bolder;
  }

  .ranking-ludus span.ludus-text {
    font-size: 8px;
    font-weight: 500;
  }

  .ranking-likes {
    width: 60px;
    text-align: end;
    color: #01393A;
  }

  .ranking-likes span {
    font-size: 12px;
    font-weight: bolder;
  }
}


.rankings-page .ranking-list .ranking-item[data-rank="1"],
.rankings-page .ranking-list .ranking-item[data-rank="2"],
.rankings-page .ranking-list .ranking-item[data-rank="3"] {
  .avatar .frame {
    display: block;
  }
}


.rankings-page .ranking-list .ranking-item.active {
  background-color: #01393A;

  .ranking-position,
  .ranking-ludus,
  .ranking-likes {
    color: white;
  }
}


.rankings-page .big-primary-square,
.rankings-page .big-secondary-square {
  position: fixed;
  aspect-ratio: 1 / 1;
  z-index: 0;
  border-radius: 18%;
}

.rankings-page .big-primary-square {
  width: 60%;
  background: #F0FFFF;
  left: -15%;
  top: -60%;
  transform: rotate(-15deg);
}

.rankings-page .big-secondary-square {
  width: 60%;
  background: #E5E5F9;
  right: -40%;
  top: 15%;
  transform: rotate(15deg);
}


// width >= 800px 
@media (min-width: 800px) {
  .rankings-page div.main-container {
    column-gap: 25px;
    max-width: 481px;

    .ranking-card {
      display: flex;
    }

    .ranking-list {
      grid-row: 3 / 4;
    }
  }

  .rankings-page .ranking-list .ranking-item[data-rank="1"],
  .rankings-page .ranking-list .ranking-item[data-rank="2"],
  .rankings-page .ranking-list .ranking-item[data-rank="3"] {
    display: none;
  }

  .rankings-page {
    padding-bottom: spacing(12);
  }
}


// width >= 900px 
@media (min-width: 900px) {
  .rankings-page div.main-container {
    margin-top: 50px;

    .ranking-card-owner {
      display: block;
    }
  }
}


// width >= 1500px 
@media (min-width: 1500px) {
  .rankings-page div.main-container {
    .ranking-card-owner {
      left: -187px;
    }
  }

  .rankings-page .ludubot-ranking {
    display: block;
    right: -340px;
  }
}