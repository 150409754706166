@use "../../../styles" as *;

.index-test-page {
  position: relative;
  opacity: 0;
  transition: opacity .4s ease-in-out;

  &.ml_display {
    opacity: 1;
  }

  .hidden{
    display: none!important;
  }

  .hide {
    opacity: 0;
  }

  #test_add_dir .icon-1,
  #test_add_template .icon-1 {
    transform: scale(1.7);
    margin: 5px 0px 0px 0px;
  }

  .ml_my-tests-background {
    border-radius: 30%;
    background: #F4A2F0;
    opacity: .6;
    width: 40rem;
    height: 40rem;
    transform: rotate(335deg);
    position: fixed;
    z-index: -1;
    right: -243px;
    top: 24%;
  }
}


.index-test-page .tests-filters-mobile {
  position: fixed;
  inset: 0px;
  width: 100%;
  height: 100dvh;
  background-color: white;
  z-index: 110;
  transition: transform .4s ease-in-out;

  ml-search .ml-icon-cerrar {
    scale: 1.5;
  }

  .close-tests-filters {
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
  }
  
  .close-tests-filters i {
    pointer-events: none;
    font-size: 50px;
    display: block;
    margin: 0px;
    width: auto;
    height: auto;
  }

  .clear-test-filter-modal {
    display: flex;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;

    i, span {
      pointer-events: none;
    }

    i {
      display: inline-block;
      margin: 0px;
      width: auto;
      height: auto;
      font-size: 25px;
    }

    span {
      font-size: 14px;
    }
  }
}


.index-test-page .index-test-body {
  padding: 30px spacing(6) 0px spacing(6);
}


.index-test-page .index-test-body .main-title {
  margin-bottom: 20px;

  h2 {
    font-size: 22px;
    line-height: 34px;
    margin: 0px;
  }
}


.index-test-page .index-test-body .create-do-test-cards {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 50px;
}

.index-test-page .index-test-body .test-card {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  padding: 20px 20px 20px 60px;
  box-sizing: border-box;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  .icon-wrap {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0px;
    left: 10px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }

  .icon-wrap i {
    margin: 0px;
    display: block;
    font-size: 30px;
    width: auto;
    height: auto;
  }

  &.card__create .icon-wrap {
    background-color: rgba(128, 112, 232, 0.2);
  }
  
  h3 {
    font-size: 14px;
    line-height: 29px;
    margin: 0px 0px 6px 0px;
  }

  p {
    font-size: 12px;
    margin: 0px 0px 18px 0px;
    line-height: 20px;
  }
  
  .btn {
    margin-top: auto;
  }

  ml-button {
    display: none;
    margin-left: auto;
    width: fit-content;
  }

  ml-link {
    display: block;
    margin-left: auto;
    width: fit-content;
  }
}


.index-test-page .index-test-body .test-section-title {
  h2 {
    font-size: 20px;
    margin: 0px;
    line-height: 32px;
    font-weight: 500;
  }

  .sub-title {
    display: none;
    align-items: center;
    height: 30px;
  }

  .sub-title span {
    font-size: 14px;
    line-height: 18px;
  }

  .sub-title>div{
    display: flex;
    align-items: center;
  }

  .sub-title i {
    font-size: 30px;
    font-weight: 700;
    margin: 0px;
    width: auto;
    height: auto;
    transform: rotate(-90deg);
  }
}


.folder-selector-for-mobile {
  box-sizing: border-box;
  padding-top: 20px;

  ml-select i {
    color: #01393A;
  }
}


.index-test-page .index-test-body .actions-tests-header {
  display: grid;
  grid-template-columns: 210px 1fr;
  box-sizing: border-box;
  padding-top: 15px;

  .filter-tests-btn-mobile,
  .filter-tests-desktop {
    margin-left: auto;
  }
}

.actions-tests-header .filter-tests-btn-mobile {
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;

  i, span {
    pointer-events: none;
  }

  i {
    margin: 0px;
    display: inline-block;
    width: auto;
    height: auto;
    font-size: 30px;
  }

  span {
    font-size: 14px;
    line-height: 18px;
  }
}

.actions-tests-header .filter-tests-desktop {
  display: none;
  align-items: center;
  column-gap: 15px;

  .clear-all {
    display: flex;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;
  }

  .clear-all i,
  .clear-all span {
    pointer-events: none;
  }

  .clear-all i {
    margin: 0px;
    font-size: 30px;
    height: auto;
    width: auto;
  }

  .clear-all span {
    font-size: 14px;
  }
}

.index-test-page .index-test-body .filter-test-badge-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  padding-block: 15px;
}


// FOLDER MANAGEMENT
.index-test-page .index-test-body .tests-and-folder-wrap {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 200px;
  margin-bottom: 50px;
}

.tests-and-folder-wrap .folder-wrap {
  display: none;
}

.tests-and-folder-wrap .folder-wrap .folder-tree input {
  width: 100%;
}

.tests-and-folder-wrap .folder-wrap .folder-tree .test-folder {
  display: flex;
  align-items: center;
  position: relative;
  column-gap: 5px;
  padding: 5px 20px;
  box-sizing: border-box;
  border-radius: 20px;

  i {
    margin: 0px;
    font-size: 30px;
    width: auto;
    height: auto;
    display: inline-block;
  }

  i.ml-folder,
  span {
    pointer-events: none;
  }

  i.ml-folder::before {
    content: "";
  }

  i.ml-icon-flecha_desplegar {
    cursor: pointer;
    margin-left: auto;
    transition: transform .3s linear;
    transform: rotate(0deg);
  }

  span {
    font-size: 14px;
    line-height: 18px;
    pointer-events: none;
  }
}

.tests-and-folder-wrap .folder-wrap .folder-tree .test-folder.ml_dir_selected {
  background-color: var(--lwarning);

  i {
    color: $dark
  }
}

.tests-and-folder-wrap .folder-wrap .folder-tree>ul {
  min-height: 0px;
  height: 0px;
  padding-left: 7px;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0px;
  transition: all .5s cubic-bezier(.77, 0, .19, 1.01);

  span.empty-folder {
    font-size: 12px;
    line-height: 18px;
    padding-left: 20px;
  }
}

.tests-and-folder-wrap .folder-wrap .folder-tree.ml_folder_open {
  .test-folder span {
    font-weight: 500;
  }

  .test-folder i.ml-folder::before {
    content: "\e824";
  }

  .test-folder i.ml-icon-flecha_desplegar {
    transform: rotate(-180deg);
    cursor: pointer;
  }

  >ul {
    min-height: auto;
    max-height: 500px;
    height: auto;
  }
}

.tests-and-folder-wrap .folder-wrap .main-folder {
  display: flex;
  align-items: center;
  column-gap: 5px;
  border-radius: 30px;
  padding: 5px 20px;
  box-sizing: border-box;

  i {
    margin: 0px;
    font-size: 30px;
    width: auto;
    height: auto;
    display: inline-block;
  }

  i.ml-folder {
    pointer-events: none;
  }

  i.ml-folder::before {
    content: "";
  }

  i.ml-icon-flecha_desplegar {
    cursor: pointer;
    margin-left: auto;
    transition: transform .3s linear;
    transform: rotate(0deg);
  }

  span {
    font-size: 14px;
    line-height: 18px;
    pointer-events: none;
  }

  &.ml_dir_selected {
    background-color: var(--lwarning);
  }

  &.ml_dir_selected i {
    color: $dark;
  }

  &.open i.ml-folder::before {
    content: "\e824";
  }

  &.open i.ml-icon-flecha_desplegar {
    transform: rotate(-180deg);
  }
}

.tests-and-folder-wrap .folder-wrap .main-folder-container {
  min-height: 0px;
  height: 0px;
  padding-inline: 7px;
  box-sizing: border-box;
  overflow: hidden;
  transition: min-height .5s cubic-bezier(.77, 0, .19, 1.01);

  &.open {
    min-height: auto;
    max-height: 500px;
    height: auto;
    overflow-y: auto;
  }
}

.tests-and-folder-wrap .folder-wrap .ml_test_item {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 5px;
  box-sizing: border-box;
  padding: 5px 20px;
  width: fit-content;

  i,
  span {
    pointer-events: none;
  }

  i {
    display: inline-block;
    margin: 0px;
    font-size: 25px;
    width: auto;
    height: auto;
  }

  span {
    font-size: 14px;
  }

  .js_input_to_change_test_name {
    width: 100%;
  }
}
// FOLDER MANAGEMENT END

.tests-and-folder-wrap .tests-wrap .test-list-with-data {
  .tests-card-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }

  .tests-card-list.space-top {
    padding-top: 50px;
  }

  >i {
    display: block;
    margin: 0px auto;
    font-size: 80px;
    width: fit-content;
    height: auto;
    cursor: pointer;
  }
}

.tests-and-folder-wrap .tests-wrap .test-list-without-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: 0 0.2rem 0.9rem 0.1rem rgb(0 0 0 / 9%);
  border-radius: 20px;
  box-sizing: border-box;
  padding: 25px 30px;
  width: 100%;

  i {
    font-size: 40px;
    margin: 0px 0px 20px 0px;
    display: block;
    width: auto;
    height: auto;
  }

  p {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }

  button {
    margin-top: 15px;
  }
}


.index-test-page .index-test-body .actions-template-header {
  margin-bottom: 50px;
}

.index-test-page .index-test-body .template-list-wrap {
  margin-bottom: 100px;

  i.ml-icon-flecha_desplegar {
    display: block;
    margin: 0px auto;
    font-size: 80px;
    width: fit-content;
    height: auto;
    cursor: pointer;
  }
}

.index-test-page .index-test-body .templates-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.index-test-page .index-test-body .template-list-wrap .templates-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: 0 0.2rem 0.9rem 0.1rem rgb(0 0 0 / 9%);
  border-radius: 20px;
  box-sizing: border-box;
  padding: 25px 30px;
  width: 100%;

  i {
    font-size: 40px;
    margin: 0px 0px 20px 0px;
    display: block;
    width: auto;
    height: auto;
  }

  p {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    max-width: 400px;
  }
}

.index-test-page .ml_menu_context {
  .js_move_options{
    .js_folder_to_move_test{
      min-height: 50px;
      max-height: 200px;
      overflow-y: auto;
      > p:hover{ font-weight: bolder; }r
      li.disabled, p.disabled{
        opacity: .3;
        pointer-events: none;
      }
      li.active, p.active{
        background-color: var(--lwarning);
        border-radius: 30px;
        i{ color: $dark }
      }
      li:hover{
        P{ font-weight: bolder; }
      }
    }
    p{
      padding: 10px 20px;
      margin: 4px 0px;
      border-radius: 30px;
      cursor: pointer;
      i,span { pointer-events: none; }
      &.ml_disabled{
        opacity: .3;
        pointer-events: none;
      }
      &.ml_dir_selected{
        background-color: var(--lwarning);
        i{ color: $dark }
      }
    }
    i{ font-size: 20px; }
    ul{
      list-style: none;
      padding-left: 15px;
      margin: 0px;
    }
  }

  .ml_btn-wrap{
    padding: 10px 20px;
    > p{
      display: flex;
      align-items: center;
      gap: 15px;
      i{
        font-size: 14px;
        transform: scale(1.5);
      }
      i.ml-icon-flecha{
        transform: scale(3);
        transition: all .3s linear;
      }
      &:hover{ font-weight: 500; }
    }

    &.ml_menu_item_top{
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      &.ml_shadow_bottom{
        -webkit-box-shadow: 0px 1px 8px -1px rgba(199,199,199,.3);
        -moz-box-shadow: 0px 1px 8px -1px rgba(199,199,199,.3);
        box-shadow: 0px 1px 8px -1px rgba(199,199,199,.3);
        i.ml-icon-flecha{
          transform: scale(3) rotate(180deg);
        }
      }
    }

    &.ml_menu_item_bottom{
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      &.ml_shadow_top{
        -webkit-box-shadow: 0px -1px 8px -1px rgba(199,199,199,.3);
        -moz-box-shadow: 0px -1px 8px -1px rgba(199,199,199,.3);
        box-shadow: 0px -1px 8px -1px rgba(199,199,199,.3);
        button{
          padding: .5em 2.5em;
          margin: 5px 0px;
        }
      }
    }
  }
}


.index-test-page .modal-remove-dir {
  box-sizing: border-box;
  padding-top: 30px;

  h3 {
    font-size: 18px;
    margin: 0px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
  }

  .content {
    margin-bottom: 20px;
  }

  .content p,
  p.text-footer {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }

  p.text-footer {
    margin-bottom: 30px;
  }

  .buttons-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .buttons-wrap button {
    width: 100%;
  }
}

.index-test-page .modal-remove-test {
  box-sizing: border-box;
  padding-top: 30px;

  p.title {
    font-size: 18px;
    font-weight: bold;
    margin: 0px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
  }

  .content {
    margin-bottom: 20px;
  }

  .content p {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }

  .buttons-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .buttons-wrap button {
    width: 100%;
  }
}

.index-test-page .modal-create-directory {
  box-sizing: border-box;
  padding-top: 30px;

  h3 {
    font-size: 18px;
    margin: 0px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
  }

  .input-wrap {
    text-align: center;
    margin-bottom: 30px;
    max-width: 350px;
    margin-inline: auto;
  }

  .input-wrap ml-input input {
    width: 100%;
  }

  .buttons-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .buttons-wrap button {
    width: 100%;
  }
}

.index-test-page .modal-remove-template {
  box-sizing: border-box;
  padding-top: 30px;

  h3 {
    font-size: 18px;
    margin: 0px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
  }

  .content {
    margin-bottom: 20px;
  }

  .content p {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }

  .buttons-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .buttons-wrap button {
    width: 100%;
  }
}

.index-test-page .modal-create-test-from-template {
  box-sizing: border-box;
  padding-top: 30px;

  p#ctft_text {
    font-size: 18px;
    font-weight: bold;
    margin: 0px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
  }

  .input-wrap {
    text-align: center;
    margin-bottom: 30px;
    max-width: 350px;
    margin-inline: auto;
  }

  .input-wrap ml-input input {
    width: 100%;
  }

  .buttons-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .buttons-wrap button {
    width: 100%;
  }
}

.index-test-page .modal-create-test {
  h3 {
    font-size: 18px;
    margin: 0px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
    max-width: 450px;
    margin-inline: auto;
  }

  .buttons-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .buttons-wrap button {
    width: 100%;
  }
}


// width >= 600px
@media (min-width: 600px) {
  .index-test-page .index-test-body .test-section-title {
    .sub-title {
      display: flex;
    }
  }

  .index-test-page .modal-create-directory,
  .index-test-page .modal-create-test-from-template {
    .buttons-wrap {
      flex-direction: row;
      column-gap: 20px;
      justify-content: center;
    }
  }
}


// width >= 900px
@media (min-width: 900px) {
  .index-test-page .index-test-body .templates-list {
    grid-template-columns: 1fr 1fr;
  }

  .index-test-page .modal-remove-template {
    .buttons-wrap {
      flex-direction: row;
      column-gap: 15px;
      justify-content: center;
    }
  }
}


// width >= 800px
@media (min-width: 800px) {
  .index-test-page .ml_my-tests-background {
    right: 0;
    bottom: 10%;
  }

  .index-test-page .index-test-body .test-section-title {
    h2 {
      font-size: 24px;
    }

    .sub-title {
      height: 33px;
    }

    .sub-title span {
      font-size: 16px;
    }
  }

  .index-test-page .index-test-body {
    padding: 20px spacing(12) 0px spacing(12);
  }
}


// width >= 1200px
@media (min-width: 1200px) {
  .index-test-page .index-test-body .test-card {
    h3 {
      font-size: 20px;
      line-height: 35px;
      margin: 0px 0px 8px 0px;
    }
  
    p {
      font-size: 16px;
      margin: 0px 0px 22px 0px;
      line-height: 24px;
    }
    
    ml-button {
      display: block;
    }
    
    ml-link {
      display: none;
    }
  }

  .tests-and-folder-wrap .tests-wrap .test-list-with-data {
    i:hover {
      font-weight: 700;
    }
  }

  .index-test-page .index-test-body .actions-template-header {
    margin-bottom: 30px;
  }

  .index-test-page .index-test-body .template-list-wrap {
    i.ml-icon-flecha_desplegar:hover {
      font-weight: 700;
    }
  }

  .index-test-page .modal-remove-dir,
  .index-test-page .modal-remove-test,
  .index-test-page .modal-create-test {
    .buttons-wrap {
      flex-direction: row;
      column-gap: 15px;
      justify-content: center;
    }
  }
}


// width >= 1400px
@media (min-width: 1400px) {
  .index-test-page .index-test-body .create-do-test-cards {
    margin-bottom: 80px;
  }

  .index-test-page .index-test-body .test-card {
    padding: 20px 20px 20px 80px;

    .icon-wrap {
      width: 50px;
      height: 50px;
      left: 15px;
    }

    .icon-wrap i {
      font-size: 32px;
      margin-top: 4px;
    }
  }

  .folder-selector-for-mobile {
    display: none;
  }

  .index-test-page .index-test-body .actions-tests-header {
    margin-bottom: 20px;
  }

  .actions-tests-header .filter-tests-btn-mobile {
    display: none;
  }

  .actions-tests-header .filter-tests-desktop {
    display: flex;
  }

  .index-test-page .index-test-body .filter-test-badge-wrap {
    display: none;
  }

  .index-test-page .index-test-body .tests-and-folder-wrap {
    grid-template-columns: 1fr 3fr;
    column-gap: 30px;

    .folder-wrap {
      display: block;
    }
  }
}


// width >= 1600px
@media (min-width: 1600px) {
  .index-test-page .index-test-body .templates-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
