@use "../../styles" as *;


.ml_invite_friends_wrap{
  opacity: 0;
  transition: opacity .4s ease-in-out;
  &.ml_display{ opacity: 1; }

  @apply ml-16 flex;

  .left-side {
    @apply flex-grow;
  }
  .right-side {
    min-width: 30%;
  }

  .li {
    @apply relative font-medium text-lg max-w-3xl last:mt-5;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @apply w-2 h-2 -left-6 rounded-full bg-primary;
    }
  }

  .bg-right {
    @apply absolute bg-secondary4;
    position: fixed;
    height: 890px;
    width: 900px;
    right: -400px;
    top: 100px;
    transform: rotate(18deg);
    border-radius: 250px;
    z-index: -5;
  }
}

