.ml_aula_login_wrap {
    opacity: 0;
    transition: opacity .4s ease-in-out;
    &.ml_display{ opacity: 1; }

    .login-background {
        border-radius: 15%;
        padding: 20px;
        width: 2400px;
        height: 2000px;
        transform: rotate(10deg);
        position: fixed;
        left: 20%;
        top: -110%;
        z-index: -1;
        @media (max-width: 1600px) {
            left: 30%;
        }
        @media (max-height: 950px) {
            top: -100%;
        }
        @media (max-width: 800px) {
            display: none;
        }
    }

    .ml_login-content {
        width: 380px;
        margin-top: 150px;
        position: relative;
        z-index: 90;
        text-align: center;

        form{
            .ml_input_form{
                text-align: start;
                display: inline-block;
                width: 80%;
                margin: 5px 0px;
            }
            .submit_form{
                display: inline-block;
                width: 80%;
                .ml-button-loading i{
                    display: flex;
                    color: white;
                    padding: 2px;
                }
            }
            .ml_input-rb-wrap{
                text-align: left;
                margin: 10px 10% 10px 10%;
            }
        }
        &.ml_recuva{
            form{
                .ml_input_form{
                    display: inline-block;
                    width: 80%;
                    margin: 20px 0px;
                }
            }
        }
        .ml_link-w:hover{
            font-weight: bold;
        }
    }

    @media (max-width: 800px) {
        background: theme('colors.primary');
        .ml_logo {
            fill: #fff;
        }
    }

    @media (max-width: 420px) {
        .ml_login-content {
            width: 90%;
            max-width: 350px;
        }
    }
}

.ml_aula_new_pass_wrap {
    .ml_aula_new_pass_content {
        width: 85%;
        margin-top: 60px;
        @media (max-width: 1600px) {
            width: 80%;
            margin-top: 40px;
        }
        .ml_aula-new-pass-2-col {
            width: 50%;
            display: inline-block;
            vertical-align: middle;
            @media (max-width: 800px) {
                width: 100%;
                text-align: center;
            }
            ul {
                @media (max-width: 800px) {
                    padding-left: 33%;
                }
                @media (max-width: 480px) {
                    padding-left: 40px;
                }
            }
            form{
                width: 300px;
                @media (max-width: 800px) {
                    margin: auto;
                }
                .ml_input_form{
                    display: inline-block;
                    width: 100%;
                    margin: 10px 0px;
                }
                .ml_submit_form{
                    display: block;
                    margin: 10px 0px;
                }
            }
            .ml_change-pass-btn {
                width: 272px;
            }
        }
        .ml_ludubot-col {
            width: 49%;
            margin: auto;
            @media (max-width: 800px) {
                width: 100%;
            }
            .ml_ludubot-new-pass {
                width: 100%;
                max-width: 322px;
            }
        }
    }
    .ml_new-pass-background {
        border-radius: 15%;
        background: #3FE0E6;
        padding: 20px;
        width: 2300px;
        height: 2300px;
        transform: rotate(10deg);
        position: fixed;
        left: 56%;
        top: -110%;
        z-index: -1;
        @media (max-width: 1600px) {}
        @media (max-height: 950px) {
            top: -150%;
            left: 60%;
        }
        @media (max-width: 800px) {
            display: none;
        }
    }
}

.ml_logo {
    width: 150px;
    vertical-align: top;
    fill: theme('colors.primary');
}

.ml_input-width { width: 76%; }

.ml_input-psw-wrap { display: block; }

.ml_justify-left { left: -37px; }
