.summary-page {
  position: relative;
  overflow-x: clip;

  ::-webkit-scrollbar {
    height: 0px;
    width: 5px;
    background: transparent;
    z-index: 90;
  }
  
  ::-webkit-scrollbar-thumb {
    background: var(--primary);
    -webkit-border-radius: 20px;
  }

  .ml_point {
    margin: 5px;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    position: relative;
    &.ml_point-line:after,
    &.ml_point-check:after,
    &.ml_point-check:before,
    &.ml_point-wrong:after,
    &.ml_point-wrong:before,
    &.ml_point-na:after {
      content: '';
      position: absolute;
      background-color: white;
      border-radius: 4px;
      height: 3px;
    }

    &.ml_point-na {
      background-color: var(--warning);
      &:after {
        top: 6px;
        left: 3px;
        width: 9px;
      }
    }

    &.ml_point-line {
      background-color: var(--primary);
      &:after {
        top: 6px;
        left: 3px;
        width: 9px;
      }
    }

    &.ml_point-check {
      background-color: var(--success);
      &:after {
        top: 6px;
        left: 4px;
        width: 11px;
        transform: rotate(-45deg);
      }

      &:before {
        top: 7px;
        left: 2px;
        width: 6px;
        transform: rotate(45deg);
      }
    }

    &.ml_point-wrong {
      background-color: var(--accent);
      &:after {
        top: 6px;
        left: 2px;
        width: 11px;
        transform: rotate(-45deg);
      }

      &:before {
        top: 6px;
        left: 2px;
        width: 11px;
        transform: rotate(45deg);
      }
    }

    &.ml_point-uncheck {
      background-color: var(--primary3);
    }
  }
}

.summary-page .top-square {
  position: absolute;
  top: 200px;
  right: -120px;
  border-radius: 30%;
  background: rgba(237, 74, 221, 0.302);
  width: 200px;
  height: 200px;
  transform: rotate(335deg);
  z-index: -1;
}

.summary-page .bottom-square {
  position: absolute;
  bottom: 200px;
  left: -280px;
  width: 450px;
  height: 450px;
  border-radius: 110px;
  background: rgba(237, 74, 221, 0.2);
  z-index: -1;
  transform: rotate(20deg);
}

.summary-page .all-info-header {
  width: 100%;
  max-width: 1260px;
  box-sizing: border-box;
  padding-inline: 29px;
  margin-inline: auto;
  padding-top: 40px;
  margin-bottom: 90px;
}

.summary-page .all-info-header .main-title {
  display: flex;
  flex-direction: column;
  column-gap: 40px;
  row-gap: 10px;
  margin-bottom: 40px;

  h1 {
    font-size: 22px;
    line-height: 34px;
    margin: 0px;
  }

  ml-button button {
    width: 100%;
  }
}

.summary-page .subtitle {
  font-size: 20px;
  line-height: 34px;
  margin: 0px;
}

.summary-page .all-info-header .score-card {
  position: relative;
  box-sizing: border-box;
  padding: 40px 80px 40px 80px;
  background-color: white;
  width: fit-content;
  border-radius: 20px;
  @apply shadow-ml;
  transform: translateX(-79px);
  display: grid;
  column-gap: 60px;
  row-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "score time" "points points";
  margin-bottom: 210px;

  .score-data {
    grid-area: score;
  }

  .time-data {
    grid-area: time;
  }

  .points-group {
    grid-area: points;
  }

  ml-ludubot-msg {
    position: absolute;
    bottom: -200px;
    right: -20px;
    z-index: 1;
  }
}

.summary-page .all-info-header .score-card .score-data,
.summary-page .all-info-header .score-card .time-data,
.summary-page .all-info-header .score-card .points-group {
  p.title {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 5px;
  }
}


.summary-page .all-info-header .score-card .score-data {
  p#score {
    font-size: 40px;
    font-weight: 800;
    color: var(--accent);
  }
  
  p#score.success {
    color: var(--success)
  }
}

.summary-page .all-info-header .score-card .time-data {
  p#time-minutes {
    font-size: 40px;
    font-weight: 800;
  }
}

.summary-page .all-info-header .score-card .points-group .points {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;

  .point-wrap {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .point-wrap i {
    margin: 0px;
    width: auto;
    height: auto;
    font-size: 30px;
  }

  .point-wrap p {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }
}


.summary-page .all-info-header .question-stat {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 20px;
  column-gap: 40px;
  margin-bottom: 20px;
}

.summary-page .all-info-header .question-stat .total-questions {
  display: flex;
  align-items: center;
  column-gap: 15px;

  i {
    margin: 0px;
    width: auto;
    height: auto;
    font-size: 50px;
  }

  >div {
    display: flex;
    align-items: flex-end;
    column-gap: 5px;
  }

  >div p.number {
    font-size: 40px;
    font-weight: 800;
    line-height: 0.9;
    margin: 0px;
  }

  >div p.title {
    font-size: 12px;
    font-weight: 500;
    margin: 0px;
  }
}

.summary-page .all-info-header .question-stat .question-progress-bar {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-template-areas: "percentage range" "progress progress";
  column-gap: 10px;
  row-gap: 15px;

  .percentage-number {
    font-size: 28px;
    font-weight: 800;
    line-height: 25px;
    grid-area: percentage;
  }

  .range-block {
    grid-area: range;
  }

  .progress-bar {
    grid-area: progress;
  }
}

.question-progress-bar .range-block {
  display: flex;
  align-items: center;
  column-gap: 10px;

  >div {
    width: 15px;
    height: 15px;
    border-radius: 5px;
    background: var(--primary);
  }

  p {
    font-size: 14px;
    line-height: 10px;
    margin: 0px;
  }

  p::after {
    content: " contestadas";
  }
}

.question-progress-bar .progress-bar {
  position: relative;
  height: 10px;
  border-radius: 20px;
  background: var(--primary3);

  >div {
    height: 100%;
    width: 0%;
    border-radius: 20px;
    background-color: var(--primary);
    animation: progress 1s ease-out forwards;
  }
}


.summary-page .all-info-header .percentage-circles-wrap {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

@keyframes progress {
  0% {
    width: 0%;
  }
}

.summary-page .summary-card-test {
  width: 100%;
  max-width: 1260px;
  box-sizing: border-box;
  padding-inline: 29px;
  margin-inline: auto;
  margin-bottom: 10px;

  .filter-txt-result {
    font-size: 14px;
    line-height: 28px;
  }
}

.summary-page .summary-card-test .tests-filters {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 600px;
  padding-top: 15px;
  row-gap: 10px;
  column-gap: 30px;
  margin-bottom: 10px;
}

.summary-page .questions-cards-container {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 30px;

  &.hidden {
    display: none!important;
  }

  .navigation-rule {
    padding: 20px 29px 20px 20px;
    box-sizing: border-box;
    display: none;
    grid-template-columns: 1fr;
    grid-template-rows: 35px 1fr fit-content(148px);
  }
}

// CARDS SECTION
.summary-page .questions-cards-container .cards-section {
  overflow: hidden;
}

.summary-page .questions-cards-container .cards-section .cards-wrap {
  height: calc(100vh - 150px);
  max-height: 510px;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-wrap: nowrap;
}

.cards-wrap .card-test {
  background: white;
  overflow-y: auto;
  padding: 40px 29px;
  height: 100%;
  box-sizing: border-box;
  width: 100vw;
  flex: 0 0 auto;

  p.law-name {
    font-size: 11px;
    line-height: 14px;
    margin-bottom: 5px;
    color: rgba(1, 59, 60, 0.749);
  }
}

.cards-wrap .card-test .question-content {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 15px;

  i {
    position: absolute;
    top: 0px;
    left: -40px;
    font-size: 30px;
    font-weight: bold;
    scale: 1.2;
    color: var(--primary);
    margin: 0px;
    width: auto;
    height: auto;
    display: none;
  }

  span {
    font-size: 16px;
    font-weight: bolder;
  }
}

.cards-wrap .card-test.failed .question-content {
  i, span {
    color: var(--accent);
  }
}

.cards-wrap .card-test.ok .question-content {
  i, span {
    color: var(--success);
  }
}

.cards-wrap .card-test.no-answer .question-content {
  i,
  span {
    color: var(--warning);
  }
}

.cards-wrap .card-test .summary-answers-wrap {
  margin-bottom: 20px;
}

.cards-wrap .card-test .answer-wrap {
  display: flex;
  align-items: flex-start;
  column-gap: 5px;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: white;

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 4px;
    flex: 1;
  }

  p[data-prefix="0"]::before {
    content: "A. ";
    font-weight: bold;
  }

  p[data-prefix="1"]::before {
    content: "B. ";
    font-weight: bold;
  }

  p[data-prefix="2"]::before {
    content: "C. ";
    font-weight: bold;
  }

  p[data-prefix="3"]::before {
    content: "D. ";
    font-weight: bold;
  }
}

.cards-wrap .card-test .answer-wrap.ok {
  background-color: var(--success3);

  p {
    font-weight: 500;
  }
}

.cards-wrap .card-test .answer-wrap.failed {
  color: var(--accent);

  p {
    font-weight: 500;
  }
}

.cards-wrap .card-test .show-explanation-toggle {
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: auto;
  width: fit-content;

  p, i {
    pointer-events: none;
  }

  i {
    font-size: 30px;
    width: auto;
    height: auto;
    display: block;
    margin: 0px;
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
    color: var(--primary);
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }

  p:after {
    content: "mostrar explicación";
  }
}

.cards-wrap .card-test .show-explanation-toggle.show {
  p:after {
    content: "ocultar explicación";
  }

  i {
    transform: rotate(180deg);
  }
}

.cards-wrap .card-test .explanation-section-skeleton {
  --loading-grey: #ededed;

  .title, .breadcrumb, .show-more-link, .subtitle, .content {
    background-color: var(--loading-grey);
    background: linear-gradient(100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, .5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--loading-grey);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s skeleton ease-in-out infinite;
  }

  .title {
    height: 22px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 110px;
  }

  .breadcrumb {
    height: 18px;
    margin-bottom: 5px;
  }

  .show-more-link {
    height: 18px;
    width: 80px;
    margin-left: auto;
    margin-bottom: 10px;
  }

  .subtitle {
    height: 20px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 110px;
  }

  .content {
    width: 100%;
    height: 18px;
    margin-bottom: 5px;
  }

  @keyframes skeleton {
    to {
      background-position-x: -20%;
    }
  }
}

.cards-wrap .card-test .explanation-section {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-in-out;

  >div {
    overflow: hidden;
  }

  h3 {
    font-size: 18px;
    line-height: 22px;
    margin: 0px 0px 10px 0px;
    text-transform: uppercase;
  }
}

.cards-wrap .card-test.failed .explanation-section h3 {
  color: var(--accent);
}

.cards-wrap .card-test.ok .explanation-section h3 {
  color: var(--success);
}

.cards-wrap .card-test.no-answer .explanation-section h3 {
  color: var(--warning);
}

.cards-wrap .card-test .explanation-section.show {
  grid-template-rows: 1fr;
}

.cards-wrap .card-test .breadcrumb-container .breadcrumb {
  height: 22px;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
  margin-bottom: 5px;

  span {
    font-size: 11px;
  }

  i {
    display: inline-block;
    margin: 0px;
    width: auto;
    height: auto;
    font-size: 15px;
    font-weight: 600;
    scale: 1.3;
    transform: rotate(-90deg) translateX(-1px);
    color: var(--primary);
  }
}

.cards-wrap .card-test .breadcrumb-container {

  &.show .breadcrumb {
    height: auto;
  }

  &.show .toggle-breadcrumb:before {
    content: "ocultar";
  }

  .toggle-breadcrumb {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
    width: fit-content;
    margin-left: auto;
  }

  .toggle-breadcrumb:before {
    content: "mostrar más";
  }
}

.cards-wrap .card-test .explanation-section h4 {
  font-size: 16px;
  margin: 0px 0px 10px 0px;
}

.cards-wrap .card-test .explanation-section .explanation {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  p {
    font-size: 14px;
    margin: 0px;
  }
}
// END CARDS SECTION

.summary-page .questions-cards-container .cards-section .card-nav-wrap {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  box-sizing: border-box;
  padding-block: 10px;

  i {
    margin: 0px;
    width: auto;
    height: auto;
    font-size: 25px;
    display: inline-block;
    pointer-events: none
  }

  .nav-to-left,
  .nav-to-right {
    @apply shadow-ml;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary);
    border-radius: 50%;
    width: 45px;
    height: 45px;
    color: white;
    cursor: pointer;
  }

  .nav-to-left.disabled,
  .nav-to-right.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
    box-shadow: unset;
    filter: grayscale(0.3);
  }

  .nav-to-left i {
    transform: rotate(90deg);
  }

  .nav-to-right i {
    transform: rotate(-90deg);
  }

  .question-nav-selector {
    position: relative;
    height: 42px;
    width: 100%;
    max-width: 200px;
    border: 2px solid var(--primary);
    border-radius: 45px;

    &.active .question-nav-options {
      max-height: 250px;
      height: auto;
    }

    &.active .label i {
      transform: rotate(0deg);
    }

    .question-nav-options {
      @apply shadow-ml;
      position: absolute;
      bottom: 50px;
      left: 0px;
      height: 0px;
      max-height: 0px;
      width: 100%;
      background: white;
      border-radius: 20px;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out,
        height 0.3s ease-in-out;
    }

    .question-nav-options .nav-scroll {
      overflow: hidden;
      max-height: 250px;
      height: 100%;
      box-sizing: border-box;
      padding-block: 20px;
      padding-inline: 15px;
    }

    .question-nav-options .options-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      height: 210px;
      overflow-y: auto;
      box-sizing: border-box;
      width: 100%;
    }
    
    .question-nav-options .options-wrap .option {
      display: flex;
      align-items: center;
      column-gap: 5px;
      cursor: pointer;

      &.hidden {
        display: none!important;
      }
    }

    .question-nav-options .options-wrap .option p,
    .question-nav-options .options-wrap .option span {
      pointer-events: none;
    }

    .question-nav-options .options-wrap .option p {
      font-size: 14px;
      line-height: 24px;
      margin: 0px;
    }

    .question-nav-options .options-wrap .option.active p {
      font-weight: 600;
    }

    .label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 10px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding-inline: 25px;
      cursor: pointer;
    }

    .label p {
      font-size: 14px;
      line-height: 24px;
      margin: 0px;
      font-weight: 500;
      pointer-events: none;
    }

    .label i {
      margin: 0px;
      width: 21px;
      height: auto;
      font-size: 20px;
      display: inline-block;
      pointer-events: none;
      transform: rotate(180deg);
      transition: transform 0.3s ease-in-out;
      color: var(--primary);
      scale: 1.5;
    }
  }
}

.summary-page .questions-cards-container .navigation-rule {
  h4 {
    font-size: 18px;
    line-height: 24px;
    margin: 0px;
    font-weight: 500;
    text-align: center;
  }

  .points-nav-section {
    overflow-y: auto;
    height: 100%;
    max-height: 380px;
  }
  
  .points-nav-section.expanded {
    max-height: 300px;
  }

  .points-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }

  .points-wrap .bar {
    position: absolute;
    top: 7px;
    width: 12px;
    height: calc(100% - 14px);
    background: var(--primary5);
    border-radius: 20px;
    z-index: -1;
  }

  .summary-points {
    @apply shadow-ml;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 20px;
    width: 210px;
  }
}

.summary-page .questions-cards-container .navigation-rule .card-pointer {
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;

  * {
    pointer-events: none;
  }

  &:hover {
    scale: 1.1;
  }

  &.active .dot {
    background: var(--primary);
    width: 15px;
    height: 15px;
  }

  &.active .card-pos {
    font-weight: 600;
  }

  &.hidden {
    display: none!important;
  }

  .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--primary3);
  }

  .card-pos {
    width: 25px;
    font-size: 12px;
  }
}

.summary-page .questions-cards-container .navigation-rule .summary-points {
  .header {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
  }

  .header i {
    display: inline-block;
    margin: 0px;
    width: auto;
    height: auto;
    font-size: 30px;
    color: var(--primary);
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
  }

  .header p {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin: 0px;
    pointer-events: none;
  }

  .header i.ml-icon-flecha_desplegar {
    transform: rotate(180deg);
  }

  .total-points {
    overflow: hidden;
    height: 0px;
    transition: height 0.3s ease-in-out;
  }

  .total-points>div {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  .total-points p {
    font-size: 14px;
  }
}

.summary-page .questions-cards-container .navigation-rule .summary-points.show {
  .header i.ml-icon-flecha_desplegar {
    transform: rotate(0deg);
  }

  .total-points {
    overflow: hidden;
    height: 86px;
  }
}

.summary-page .no-result-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 100%;

  &.hidden {
    display: none!important;
  }
}



@media (min-width: 380px) {
  .summary-page .all-info-header .percentage-circles-wrap {
    justify-content: space-between;
    gap: 0px;
    flex-wrap: nowrap;
  }
}



@media (min-width: 480px) {
  .summary-page .all-info-header .score-card {
    ml-ludubot-msg {
      right: -110px;
    }
  }

  .summary-page .summary-card-test .tests-filters {
    grid-template-columns: repeat(3, 1fr);
  }
}


@media (min-width: 580px) {
  .summary-page .all-info-header .score-card {
    margin-bottom: 80px;

    ml-ludubot-msg {
      bottom: -50px;
      right: -200px;
    }
  }

  .summary-page .all-info-header .percentage-circles-wrap {
    justify-content: unset;
    column-gap: 80px;
  }
}


@media (min-width: 600px) {
  .summary-page .all-info-header .main-title {
    flex-direction: row;

    ml-button button {
      width: fit-content;
    }
  }

  .summary-page .all-info-header {
    margin-bottom: 70px;
  }
}


@media (min-width: 680px) {
  .summary-page .all-info-header .score-card .score-data,
  .summary-page .all-info-header .score-card .time-data,
  .summary-page .all-info-header .score-card .points-group {
    p.title {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 7px;
    }
  }
}


@media (min-width: 720px) {
  .summary-page .all-info-header .question-stat {
    grid-template-columns: 170px 1fr;
    padding-top: 20px;
    max-width: 800px;
    margin-left: -8px;
  }

  .summary-page .all-info-header .question-stat .total-questions {
    i {
      font-size: 65px;
    }

    >div {
      display: block;
    }
  }

  .summary-page .all-info-header .question-stat .question-progress-bar {
    grid-template-areas: "percentage range" "percentage progress";
    row-gap: 0px;
    column-gap: 5px;
    margin-bottom: 10px;
    align-self: end;

    .percentage-number {
      line-height: normal;
      align-self: center;
    }
  }

  .question-progress-bar .range-block {
    p::after {
      content: " preguntas contestadas";
    }
  }

  .summary-page .all-info-header .percentage-circles-wrap {
    margin-left: -8px;
  }
}


@media (min-width: 780px) {
  .summary-page .all-info-header .score-card {
    grid-template-columns: fit-content(150px) fit-content(150px) 1fr;
    grid-template-areas: "score time points";

    .points-group {
      transform: translateY(24px);
    }
  
    ml-ludubot-msg {
      bottom: -90px;
    }
  }

  .summary-page .all-info-header .score-card .score-data {
    p#score {
      font-size: 60px;
    }
  }

  .summary-page .all-info-header .score-card .time-data {
    p#time-minutes {
      font-size: 60px;
    }
  }
}


@media (min-width: 800px) {
  .summary-page .all-info-header,
  .summary-page .summary-card-test {
    padding-inline: 50px;
  }

  .summary-page .all-info-header .percentage-circles-wrap {
    column-gap: 0px;
    justify-content: space-between;
  }

  .summary-page .all-info-header .score-card {
    ml-ludubot-msg {
      bottom: -10px;
      right: -150px;
    }
  }
}


@media (min-width: 800px) {
  .summary-page .all-info-header .score-card {
    ml-ludubot-msg {
      bottom: -90px;
      right: -200px;
    }
  }
}


@media (min-width: 900px) {
  .summary-page .all-info-header .percentage-circles-wrap {
    column-gap: 80px;
    justify-content: unset;
  }

  .summary-page .questions-cards-container {
    grid-template-columns: 1fr 230px;
    width: 100%;
    max-width: 1260px;
    margin-inline: auto;
    box-sizing: border-box;
    padding-inline: 0px;
    column-gap: 0px;

    .navigation-rule {
      display: grid;
    }
  }

  .summary-page .questions-cards-container .cards-section {
    .card-nav-wrap {
      display: none;
    }
  }

  .summary-page .questions-cards-container .cards-section .cards-wrap {
    flex-wrap: unset;
    flex-direction: column;
    height: calc(100vh - 200px);
    max-height: unset;
    overflow-x: auto;
    overflow-y: auto;
    row-gap: 30px;
    box-sizing: border-box;
    padding: 20px 29px;
    scroll-behavior: smooth;
  }

  .cards-wrap .card-test {
    @apply shadow-ml;
    border-radius: 20px;
    height: auto;
    width: 100%;
    padding-inline: 90px;
  }

  .cards-wrap .card-test .question-content {
    i {
      display: inline-block;
    }
  }
}


@media (min-width: 1000px) {
  .summary-page .all-info-header .main-title {
    h1 {
      font-size: 26px;
    }
  }

  .summary-page .all-info-header .score-card {
    transform: translateX(0px);

    ml-ludubot-msg {
      bottom: -180px;
      right: -200px;
    }
  }
}


@media (min-width: 1200px) {
  .summary-page .all-info-header .score-card {
    ml-ludubot-msg {
      right: -300px;
    }
  }
}


@media (min-width: 1300px) {
  .summary-page .all-info-header,
  .summary-page .summary-card-test {
    padding-inline: 29px;
  }
}
