@use "../../../styles" as *;

.ml_memory_tests_wrap{
  color: color(text);
  opacity: 0;
  transition: opacity .4s ease-in-out;
  position: relative;

  .main_container{
    padding-top: 180px;
    padding-left: spacing(12);
    @media (max-width:968px){
      padding-left: spacing(6);
    }
    .container {
      padding-left: spacing(9);

      h2 {
        font-weight: 500;
        margin-top: spacing(15);
        margin-bottom: spacing(4);
      }

      .text{
        &-1{
          font-size: 16px;
          font-weight: 600;
        }

        &-2{
          font-size: 26px;
          font-weight: 600;
        }
      } 

      .steps-row {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-top: spacing(5);
        column-gap: spacing(9);
        flex-wrap: wrap;

        > *:not(.emoji-memo) {
          flex: 0 0 245px;
        }

        .emoji-memo img{
          width: 120px;
          height: 120px;
        }

        .column-1 {
          display: flex;
          flex-direction: column;
          > p {
            margin-bottom: spacing(3);
            color: #416b6b;
            font-weight: 300;
          }

        }

        .step {
          display: flex;
          column-gap: 12px;
          align-items: flex-end;
          color: #416b6b;
          font-weight: 300;

          .num {
            color: color(text);
            line-height: .95;
            font-size: 50px;
            font-weight: 600;
          }
        }

        .button {
          margin-top: spacing(8);
          flex: 1 1 100%;
          width: 100%;
          padding-left: spacing(39);
        }
      }

      .redo-tests {
        padding-right: spacing(6);
        padding-left: spacing(6);
      }

      .memo-cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 45px;
        margin-bottom: 35px;
        row-gap: 25px;
        margin-bottom: 35px;
        margin-top: 25px;
        padding-right: 50px;

        .memo-card {
          background: white;
          flex: 1 0 40%;
          display: flex;
          flex-direction: row;
          box-shadow: 0 1px 6px 0px #e4e4e4;
          padding: 16px 28px;
          border-radius: 20px;

          > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
          }

          .left {
            font-size: 16px;
            .title {
              font-size: 18px;
              font-weight: 600;
              margin-bottom: spacing(2);
            }
            .subtitle {
              font-weight: 300;
            }
          }

          .right {
            cursor: pointer;
            margin-left: auto;
            align-items: center;
            .ml-icon { font-size: 35px; }
            * { pointer-events: none; }
          }
        }
      }

      .more-arrow {
        flex: 1 0 100%;
        display: flex;

        .ml-icon {
          cursor: pointer;
          margin: 0 auto;
          font-size: 80px;
        }
      }
    }
  }

  &.ml_display{ opacity: 1; }

  h1 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: spacing(2);
  }

  oposition-title {
    text-align: left;
    letter-spacing: 0;
    color: color(text);
  }

  .bg-square {
    width: 50vw;
    height: 50vw;
    border-radius: 220px;
    position: fixed;
    top: 180px;
    right: -220px;
    transform: rotate(70deg);
    background: #e7e2fa;
    z-index: -1;
  }
}

