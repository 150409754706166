@use "../../../styles" as *;

.do_test-section {
  position: relative;
  width: 100%;
  overflow-x: clip;

  .ml_point {
    margin: 5px;
    width: 15px;
    height: 15px;
    border-radius: 3px;
    position: relative;

    &.ml_point-line:after,
    &.ml_point-check:after,
    &.ml_point-check:before,
    &.ml_point-wrong:after,
    &.ml_point-wrong:before,
    &.ml_point-na:after {
      content: '';
      position: absolute;
      background-color: white;
      border-radius: 4px;
      height: 3px;
    }

    &.ml_point-na {
      background-color: var(--warning);

      &:after {
        top: 6px;
        left: 3px;
        width: 9px;
      }
    }

    &.ml_point-line {
      background-color: var(--primary);

      &:after {
        top: 6px;
        left: 3px;
        width: 9px;
      }
    }

    &.ml_point-check {
      background-color: var(--success);

      &:after {
        top: 6px;
        left: 4px;
        width: 11px;
        transform: rotate(-45deg);
      }

      &:before {
        top: 7px;
        left: 2px;
        width: 6px;
        transform: rotate(45deg);
      }
    }

    &.ml_point-wrong {
      background-color: var(--accent);

      &:after {
        top: 6px;
        left: 2px;
        width: 11px;
        transform: rotate(-45deg);
      }

      &:before {
        top: 6px;
        left: 2px;
        width: 11px;
        transform: rotate(45deg);
      }
    }

    &.ml_point-uncheck {
      background-color: var(--primary3);
    }
  }
}

.do_test-section .modal-go-out {
  box-sizing: border-box;
  padding-top: 30px;

  p.title {
    font-size: 18px;
    font-weight: bold;
    margin: 0px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
  }

  .content {
    margin-bottom: 20px;
  }

  .content p {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }

  .buttons-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .buttons-wrap button {
    width: 100%;
  }
}

.do_test-section .big-square {
  position: absolute;
  bottom: 180px;
  left: -380px;
  width: 500px;
  height: 500px;
  border-radius: 130px;
  background: rgba(237, 74, 221, 0.2);
  z-index: -1;
  transform: rotate(-20deg);
}

.do_test-section .small-square {
  position: absolute;
  bottom: 100px;
  right: -150px;
  border-radius: 30%;
  background: rgba(237, 74, 221, 0.302);
  width: 200px;
  height: 200px;
  transform: rotate(335deg);
  z-index: -1;
}

.do_test-section .do-test-layout {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 80px 1fr 118px;
  grid-template-areas: "title-and-menu"
    "timer-ruler-summary-section"
    "cards-section"
    "actions-section";
  width: 100%;
  max-width: 1318px;
  margin-inline: auto;
  height: calc(100dvh - 61px);

  .title-and-menu {
    grid-area: title-and-menu;
    box-sizing: border-box;
    padding-inline: 29px;
    padding-top: 10px;
  }

  .cards-section {
    grid-area: cards-section;
  }

  .timer-ruler-summary-section {
    grid-area: timer-ruler-summary-section;
  }

  .actions-section {
    grid-area: actions-section;
  }

  .qu-checked p:after {
    content: " sin corregir";
  }

  .qu-right p:after {
    content: " acertadas";
  }

  .qu-wrong p:after {
    content: " falladas";
  }

  .qu-no-answer p:after {
    content: " sin contestar";
  }
}

.do_test-section .do-test-layout .title-and-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 22px;
    font-weight: 600;
    line-height: 34px;
    margin: 0px;
  }

  .menu-wrap {
    position: relative;
    display: none;
    width: 28px;
    height: 28px;
  }
}

.do_test-section .do-test-layout .title-and-menu .menu-wrap {
  .btn {
    cursor: pointer;
    font-weight: bolder;
    color: var(--primary);
    font-size: 20px;
    text-align: center;
    transform: rotate(90deg);
  }

  // MENU CARD
  .menu-card {
    @apply shadow-ml;
    position: absolute;
    z-index: 10;
    top: 40px;
    right: 0px;
    width: 215px;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    box-sizing: border-box;
    display: none;
    opacity: 0;
    transform: translateY(-40px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  .menu-card.show-menu {
    display: block;
  }

  .menu-card.animate {
    opacity: 1;
    transform: translateY(0px);
  }

  .menu-card .toggle-answers {
    display: flex;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;
  }

  .menu-card .toggle-answers i {
    margin: 0px;
    width: auto;
    height: auto;
    font-size: 25px;
    color: var(--primary);
    pointer-events: none;
  }

  .menu-card .toggle-answers p {
    font-size: 14px;
    pointer-events: none;
  }

  .menu-card .toggle-answers p:before {
    content: "mostrar respuestas";
  }

  .menu-card .toggle-answers.show-answer p:before {
    content: "ocultar respuestas";
  }
  // END MENU CARD

  // MENU BODY
  .font-sizes {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 20px;
  }

  .font-sizes .btn-size {
    cursor: pointer;
    text-decoration: underline;
    width: 40px;
    text-align: center;

    &.active {
      font-weight: bold;
    }
  }

  .font-sizes .btn-size[data-size="sm"] {
    font-size: 14px;
  }

  .font-sizes .btn-size[data-size="md"] {
    font-size: 18px;
  }

  .font-sizes .btn-size[data-size="lg"] {
    font-size: 22px;
  }
  // END MENU BODY
}

.do_test-section .do-test-layout .actions-section {
  @apply shadow-ml;
  box-sizing: border-box;
  padding-block: 10px;
  background-color: white;
}

.do_test-section .do-test-layout .actions-section .mobile-action {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 100%;
  padding-inline: 5px;
  box-sizing: border-box;
  max-width: 510px;
  margin-inline: auto;

  .top-action {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 45px 1fr 1fr 45px;
    column-gap: 15px;
    width: 100%;
  }

  .top-action .nav-to-left i,
  .top-action .nav-to-right i {
    margin: 0px;
    width: auto;
    height: auto;
    font-size: 25px;
    display: inline-block;
    pointer-events: none
  }

  .top-action .nav-to-left,
  .top-action .nav-to-right {
    @apply shadow-ml;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary);
    border-radius: 50%;
    width: 45px;
    height: 45px;
    color: white;
    cursor: pointer;
  }

  .top-action .nav-to-left.disabled,
  .top-action .nav-to-right.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
    box-shadow: unset;
    filter: grayscale(0.3);
  }

  .top-action .nav-to-left i {
    transform: rotate(90deg);
  }

  .top-action .nav-to-right i {
    transform: rotate(-90deg);
  }


  .bottom-action {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    overflow: hidden;
    border-radius: 20px;
  }

  .bottom-action>div {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
    padding-block: 10px;
    color: white;
  }

  .bottom-action .out {
    background-color: var(--accent);
  }

  .bottom-action .check {
    background-color: var(--secondary);

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .bottom-action .end-it {
    background-color: var(--primary);
  }

  .bottom-action.only-evaluate {
    grid-template-columns: 1fr 1fr;
  }

  .bottom-action.only-evaluate .check {
    display: none;
  }
}

.mobile-action .top-action .question-nav-selector { 
  position: relative;
  height: 42px;
  width: 100%;
  border: 2px solid var(--primary);
  border-radius: 45px;

  &.active .question-nav-options {
    max-height: 250px;
    height: auto;
  }

  &.active .label i {
    transform: rotate(0deg);
  }

  .question-nav-options {
    @apply shadow-ml;
    position: absolute;
    bottom: 50px;
    left: 0px;
    height: 0px;
    max-height: 0px;
    width: 175px;
    background: white;
    border-radius: 20px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out,
      height 0.3s ease-in-out;
  }

  .question-nav-options .nav-scroll {
    overflow: hidden;
    max-height: 250px;
    height: 100%;
    box-sizing: border-box;
    padding-block: 20px;
    padding-inline: 15px;
  }

  .question-nav-options .options-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    height: 210px;
    overflow-y: auto;
    box-sizing: border-box;
    width: 100%;
  }

  .question-nav-options .options-wrap .option {
    display: flex;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;

    &.hidden {
      display: none !important;
    }
  }

  .question-nav-options .options-wrap .option p,
  .question-nav-options .options-wrap .option span {
    pointer-events: none;
  }

  .question-nav-options .options-wrap .option p {
    font-size: 14px;
    line-height: 24px;
    margin: 0px;
  }

  .question-nav-options .options-wrap .option.active p {
    font-weight: 600;
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-inline: 25px;
    cursor: pointer;
  }

  .label p {
    font-size: 12px;
    line-height: 20px;
    margin: 0px;
    font-weight: 500;
    pointer-events: none;
  }

  .label i {
    margin: 0px;
    width: 21px;
    height: auto;
    font-size: 24px;
    display: inline-block;
    pointer-events: none;
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
    color: var(--primary);
    scale: 1.5;
  }
}

.mobile-action .top-action .question-summary {
  position: relative;
  height: 42px;
  width: 100%;
  border: 2px solid var(--primary);
  border-radius: 45px;

  .summary-wrap {
    display: none;
    @apply shadow-ml;
    position: absolute;
    bottom: 52px;
    right: 0px;
    width: 185px;
    background: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    opacity: 0;
    transform: translateY(40px);
    transition: opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }

  &.show .summary-wrap {
    display: block;
  }

  &.animate .summary-wrap {
    opacity: 1;
    transform: translateY(0px);
  }

  .summary-wrap>div {
    display: flex;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;

    * {
      pointer-events: none;
    }
  }

  .summary-wrap p {
    font-size: 14px;
    line-height: 24px;
  }

  .label {
    display: flex;
    align-items: center;
    column-gap: 5px;
    height: 100%;
    box-sizing: border-box;
    padding-inline: 10px;
  }

  .label i {
    margin: 0px;
    width: auto;
    height: auto;
    font-size: 20px;
    pointer-events: none;
    color: var(--primary);
  }

  .label p {
    font-size: 12px;
    line-height: 24px;
    margin: 0px;
    font-weight: 500;
    pointer-events: none;
  }
}

.do_test-section .do-test-layout .actions-section .desktop-action {
  display: none;
  flex-direction: column;
  row-gap: 15px;
  width: 100%;
  box-sizing: border-box;
  padding-inline: 29px;

  .progress {
    display: flex;
    align-items: center;
    column-gap: 10px;
    width: 100%;
  }

  .progress p {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    margin: 0px;
    color: #707070;
    width: fit-content(200px);
  }

  .progress p:after {
    content: " preguntas";
  }

  .progress .bar-container {
    width: 100%;
    height: 10px;
    background: var(--primary3);
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex: 1;
  }

  .progress .bar-container .bar-success,
  .progress .bar-container .bar-failed,
  .progress .bar-container .bar-no-answer,
  .progress .bar-container .bar-seen {
    height: 100%;
    border-radius: 20px;
    position: relative;
    transition: width 0.3s ease-in-out,
      opacity 0.3s ease-in-out;

    &.hide {
      opacity: 0;
    }
  }

  .progress .bar-container .bar-failed:before,
  .progress .bar-container .bar-no-answer:before,
  .progress .bar-container .bar-seen:before {
    content: "";
    position: absolute;
    top: 0px;
    left: -6px;
    display: inline-block;
    width: 12px;
    height: 10px;
  }

  .progress .bar-container .bar-success {
    z-index: 5;
    background: var(--success);
  }

  .progress .bar-container .bar-failed {
    z-index: 4;
    background: var(--accent);
  }

  .progress .bar-container .bar-failed:before {
    background: var(--accent);
  }

  .progress .bar-container .bar-no-answer {
    z-index: 3;
    background: var(--warning);
  }

  .progress .bar-container .bar-no-answer:before {
    background: var(--warning);
  }

  .progress .bar-container .bar-seen {
    z-index: 2;
    background: var(--primary);
  }

  .progress .bar-container .bar-seen:before {
    background: var(--primary);
  }

  .buttons-group {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  }
}


.do_test-section .do-test-layout .timer-ruler-summary-section .desktop-timer {
  display: none;
}

.do_test-section .do-test-layout .timer-ruler-summary-section .mobile-timer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  width: 381px;
  overflow-x: clip;
  height: 100%;
  padding-inline: 29px;
  box-sizing: border-box;
}

.mobile-timer .mobile-toggle-answers {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0px;
  width: 70px;
  text-align: center;
  cursor: pointer;

  i {
    font-size: 30px;
    font-weight: 600;
    margin: 0px;
    width: auto;
    height: auto;
    pointer-events: none;
    color: var(--primary);
  }

  p {
    font-size: 12px;
    line-height: 14px;
    margin: 0px;
    font-weight: 500;
    pointer-events: none;
  }

  p:before {
    content: "mostrar respuestas";
  }

  &.show-answer p:before {
    content: "ocultar respuestas";
  }
}

.mobile-timer .timer-card {
  @apply shadow-ml;
  display: flex;
  align-items: center;
  height: 65px;
  box-sizing: border-box;
  padding: 12px 10px;
  border-radius: 20px;
  background: white;

  p.time {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    width: 80px;
    overflow: hidden;
    transition: width 0.3s ease-in-out;
  }

  .pause-timer {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
  }

  .pause-timer span {
    font-family: 'fontello';
    font-size: 30px;
    font-weight: bold;
    scale: 1.3;
    color: var(--primary);
    pointer-events: none;
  }

  .pause-timer span:before {
    content: '\e884';
  }

  .pause-timer p {
    font-size: 12px;
    font-weight: 500;
    transition: font-size 0.5s ease-in-out;
    pointer-events: none;
  }

  .pause-timer p:before {
    content: "pausar";
  }

  >i {
    margin: 0px;
    width: 60px;
    height: auto;
    font-size: 30px;
    color: var(--primary);
    font-weight: bold;
    scale: 1.5;
    transform: rotate(90deg);
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
}

.mobile-timer .timer-card.fold {
  padding: 12px 20px;

  p.time {
    width: 0px;
  }

  .pause-timer {
    cursor: default;
    pointer-events: none;
  }

  .pause-timer span {
    scale: 1;
  }

  .pause-timer span:before {
    content: '\e896';
  }

  .pause-timer p {
    font-size: 20px;
  }

  .pause-timer p:before {
    content: "Tiempo";
  }

  >i {
    transform: rotate(-90deg);
  }
}

.do_test-section .do-test-layout .timer-ruler-summary-section .desktop-timer {
  display: none;
  grid-template-rows: auto 25px 1fr auto;
  height: calc(100dvh - 200px);
  box-sizing: border-box;
  padding: 10px 20px 10px 10px;
  gap: 10px;

  .points-title {
    font-size: 18px;
    line-height: 24px;
    margin: 0px;
    font-weight: 500;
    text-align: center;
  }
}

.desktop-timer .timer-card {
  @apply shadow-ml;
  border-radius: 20px;
  background: white;
  width: 209px;
  box-sizing: border-box;
  padding: 15px 20px;

  &.fold .timer-body {
    height: 0px;
  }

  &.fold .timer-header i.ml-icon-flecha_desplegar {
    transform: rotate(0deg);
  }

  .timer-header {
    display: flex;
    align-items: center;
    column-gap: 10px;
    width: 100%;
    cursor: pointer;

    * {
      pointer-events: none;
    }
  }

  .timer-header p {
    font-size: 14px;
    font-weight: 600;
  }

  .timer-header i {
    margin: 0px;
    width: auto;
    height: auto;
    font-size: 20px;
    scale: 1.5;
    color: var(--primary);
  }

  .timer-header i.ml-icon-flecha_desplegar {
    margin-left: auto;
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }

  .timer-body {
    display: flex;
    align-items: center;
    column-gap: 8px;
    height: 52px;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
  }

  .timer-body p.time {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    width: 100px;
    margin-top: 15px;
  }

  .timer-body .pause-timer {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    text-align: center;
    width: 61px;
    margin-left: auto;
    margin-top: 5px;
  }

  .timer-body .pause-timer span {
    font-family: 'fontello';
    color: var(--primary);
    font-size: 20px;
    scale: 1.5;
    pointer-events: none;
  }

  .timer-body .pause-timer span:before {
    content: '\e884';
  }

  .timer-body .pause-timer p {
    font-size: 12px;
    font-weight: 500;
    pointer-events: none;
  }

  .timer-body .pause-timer p:before {
    content: "pausar";
  }

  .timer-body .pause-timer.pause span {
    scale: 1;
  }

  .timer-body .pause-timer.pause span:before {
    content: '\e887';
  }

  .timer-body .pause-timer.pause p:before {
    content: "reanudar";
  }
}

.do_test-section .do-test-layout .timer-ruler-summary-section .desktop-timer .summary-points {
  @apply shadow-ml;
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: white;
}


.do_test-section .do-test-layout .timer-ruler-summary-section .desktop-timer .points-nav-section {
  overflow-y: auto;

  .points-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    height: auto;
  }
  
  .points-wrap .bar {
    position: absolute;
    top: 7px;
    width: 12px;
    height: calc(100% - 14px);
    background: var(--primary5);
    border-radius: 20px;
    z-index: -1;
  }

  .card-pointer {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;

    * {
      pointer-events: none;
    }

    &:hover {
      scale: 1.1;
    }

    &.active .dot {
      background: var(--primary);
      width: 15px;
      height: 15px;
    }

    &.active .card-pos {
      font-weight: 600;
    }

    &.hidden {
      display: none !important;
    }

    .dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: var(--primary3);
    }

    .card-pos {
      width: 25px;
      font-size: 12px;
    }
  }
}


// TEST CARD SECTION
.do_test-section .do-test-layout .cards-section {
  --font-size: 16px;
  font-size: var(--font-size);
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-wrap: nowrap;
}

.cards-section .card-test {
  background: white;
  overflow-y: auto;
  padding: 40px 29px;
  height: 100%;
  box-sizing: border-box;
  width: 100vw;
  flex: 0 0 auto;

  p.law-name {
    font-size: 0.6875em;
    line-height: 1.5em;
    margin-bottom: 0.3125em;
    color: rgba(1, 59, 60, 0.749);
    transition: font-size 0.3s ease-in-out;
  }
}

.cards-section .card-test .question-content {
  position: relative;
  font-size: 0.875em;
  line-height: 1.25em;
  font-weight: bold;
  margin-bottom: 15px;
  transition: font-size 0.3s ease-in-out;

  i {
    position: absolute;
    top: 0px;
    left: -40px;
    font-size: 30px;
    font-weight: bold;
    scale: 1.2;
    color: var(--primary);
    margin: 0px;
    width: auto;
    height: auto;
    display: none;
  }

  span {
    font-size: 1em;
    font-weight: bolder;
    transition: font-size 0.3s ease-in-out;
  }
}

.cards-section .card-test.failed .question-content {

  i,
  span {
    color: var(--accent);
  }
}

.cards-section .card-test.ok .question-content {

  i,
  span {
    color: var(--success);
  }
}

.cards-section .card-test.no-answer .question-content {

  i,
  span {
    color: var(--warning);
  }
}

.cards-section .card-test .answers-wrap {
  margin-bottom: 20px;
  overflow: hidden;
  max-height: 0px;
  transition: height 0.3s ease-in-out,
    max-height 0.3s ease-in-out;

  &.show {
    max-height: 400px;
  }
}

.cards-section .card-test .answers-wrap.evaluated {
  cursor: default;
  
  * {
    pointer-events: none;
  }
}

.cards-section .card-test .answer-wrap {
  display: flex;
  align-items: flex-start;
  column-gap: 5px;
  padding: 0.3125em 0.625em;
  border-radius: 1.25em;
  background-color: white;
  cursor: pointer;

  * {
    pointer-events: none;
  }

  p {
    font-size: 0.75em;
    font-weight: 400;
    line-height: 1.25em;
    margin-top: 4px;
    flex: 1;
    transition: font-size 0.3s ease-in-out;
  }

  p[data-prefix="0"]::before {
    content: "A. ";
    font-weight: bold;
  }

  p[data-prefix="1"]::before {
    content: "B. ";
    font-weight: bold;
  }

  p[data-prefix="2"]::before {
    content: "C. ";
    font-weight: bold;
  }

  p[data-prefix="3"]::before {
    content: "D. ";
    font-weight: bold;
  }

  .ml_point-check {
    background-color: var(--primary);
  }
}

.cards-section .card-test .answer-wrap.ok {
  background-color: var(--success3);

  p {
    font-weight: 500;
  }

  .ml_point-check {
    background-color: var(--success);
  }
}

.cards-section .card-test .answer-wrap.failed {
  color: var(--accent);

  p {
    font-weight: 500;
  }

  .ml_point-check {
    background-color: var(--success);
  }
}

.cards-section .card-test .show-explanation-toggle {
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: auto;
  width: fit-content;

  p,
  i {
    pointer-events: none;
  }

  i {
    font-size: 30px;
    width: auto;
    height: auto;
    display: block;
    margin: 0px;
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
    color: var(--primary);
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }

  p:after {
    content: "mostrar explicación";
  }

  &.hidden {
    display: none!important;
  }
}

.cards-section .card-test .show-explanation-toggle.show {
  p:after {
    content: "ocultar explicación";
  }

  i {
    transform: rotate(180deg);
  }
}

.cards-section .card-test .show-answers-toggle {
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  margin-left: auto;
  width: fit-content;

  p,
  i {
    pointer-events: none;
  }

  i {
    font-size: 30px;
    width: auto;
    height: auto;
    display: block;
    margin: 0px;
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
    color: var(--primary);
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }

  p:after {
    content: "mostrar respuestas";
  }
}

.cards-section .card-test .show-answers-toggle.show {
  p:after {
    content: "ocultar respuestas";
  }

  i {
    transform: rotate(180deg);
  }
}

.cards-section .card-test .explanation-section-skeleton {
  --loading-grey: #ededed;

  .title,
  .breadcrumb,
  .show-more-link,
  .subtitle,
  .content {
    background-color: var(--loading-grey);
    background: linear-gradient(100deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(255, 255, 255, .5) 50%,
        rgba(255, 255, 255, 0) 60%) var(--loading-grey);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s skeleton ease-in-out infinite;
  }

  .title {
    height: 22px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 110px;
  }

  .breadcrumb {
    height: 18px;
    margin-bottom: 5px;
  }

  .show-more-link {
    height: 18px;
    width: 80px;
    margin-left: auto;
    margin-bottom: 10px;
  }

  .subtitle {
    height: 20px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 110px;
  }

  .content {
    width: 100%;
    height: 18px;
    margin-bottom: 5px;
  }

  @keyframes skeleton {
    to {
      background-position-x: -20%;
    }
  }
}

.cards-section .card-test .explanation-section {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-in-out;

  >div {
    overflow: hidden;
  }

  h3 {
    font-size: 18px;
    line-height: 22px;
    margin: 0px 0px 10px 0px;
    text-transform: uppercase;
  }
}

.cards-section .card-test.failed .explanation-section h3 {
  color: var(--accent);
}

.cards-section .card-test.ok .explanation-section h3 {
  color: var(--success);
}

.cards-section .card-test.no-answer .explanation-section h3 {
  color: var(--warning);
}

.cards-section .card-test .explanation-section.show {
  grid-template-rows: 1fr;
}

.cards-section .card-test .breadcrumb-container .breadcrumb {
  height: 22px;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
  margin-bottom: 5px;

  span {
    font-size: 11px;
  }

  i {
    display: inline-block;
    margin: 0px;
    width: auto;
    height: auto;
    font-size: 15px;
    font-weight: 600;
    scale: 1.3;
    transform: rotate(-90deg) translateX(-1px);
    color: var(--primary);
  }
}

.cards-section .card-test .breadcrumb-container {

  &.show .breadcrumb {
    height: auto;
  }

  &.show .toggle-breadcrumb:before {
    content: "ocultar";
  }

  .toggle-breadcrumb {
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
    width: fit-content;
    margin-left: auto;
  }

  .toggle-breadcrumb:before {
    content: "mostrar más";
  }
}

.cards-section .card-test .explanation-section h4 {
  font-size: 16px;
  margin: 0px 0px 10px 0px;
}

.cards-section .card-test .explanation-section .explanation {
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  p {
    font-size: 14px;
    margin: 0px;
  }
}

.cards-section .card-test .card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  p {
    font-size: 12px;
    color: #cccccc;
    margin-right: auto;
  }

  p:before {
    content: "Id pregunta ";
  }

  button {
    display: none;
  }
}
// END TEST CARD SECTION




.desktop-timer .summary-points {
  .header {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
  }

  .header i {
    display: inline-block;
    margin: 0px;
    width: auto;
    height: auto;
    font-size: 30px;
    color: var(--primary);
    pointer-events: none;
    transition: transform 0.3s ease-in-out;
  }

  .header i.ml-icon-flecha_desplegar {
    margin-left: auto;
    transform: rotate(180deg);
  }

  .header p {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin: 0px;
    pointer-events: none;
  }

  .total-points {
    overflow: hidden;
    height: 0px;
    transition: height 0.3s ease-in-out;
  }

  .total-points>div {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  .total-points p {
    font-size: 14px;
  }
}

.desktop-timer .summary-points.show {
  .header i.ml-icon-flecha_desplegar {
    transform: rotate(0deg);
  }

  .total-points {
    overflow: hidden;
    height: 114px;
  }
}



@media (min-width: 420px) {
  .do_test-section .do-test-layout .timer-ruler-summary-section .mobile-timer {
    width: 420px;
  }
}


@media (min-width: 480px) {
  .mobile-action .top-action .question-nav-selector {
    .label p {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .mobile-action .top-action .question-summary {
    .label {
      padding-inline: 29px;
    }

    .label i {
      font-size: 24px;
    }

    .label p {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .do_test-section .do-test-layout .timer-ruler-summary-section .mobile-timer {
    width: 100%;
  }

  .mobile-timer .timer-card {
    padding: 12px 20px;

    p.time {
      font-size: 30px;
      width: 130px;
    }
  }

  .mobile-action .top-action .question-nav-selector {
    .question-nav-options {
      width: 100%;
    }
  }
}


@media (min-width: 580px) {
  .mobile-action .top-action .question-summary {
    .summary-wrap {
      width: 100%;
    }
  }
}


@media (min-width: 900px) {
  .do_test-section .do-test-layout {
    grid-template-columns: 1fr 230px;
    grid-template-rows: 40px 1fr 94px;
    grid-template-areas: "title-and-menu ."
      "cards-section timer-ruler-summary-section"
      "actions-section .";
  }

  .do_test-section .do-test-layout .title-and-menu {
    .menu-wrap {
      display: inline-block;
    }
  }

  .do_test-section .do-test-layout .actions-section .desktop-action {
    display: flex;
  }

  .do_test-section .do-test-layout .actions-section {
    box-shadow: unset;
    background-color: transparent;

    .mobile-action {
      display: none;
    }
  }

  .do_test-section .do-test-layout .timer-ruler-summary-section .mobile-timer {
    display: none;
  }

  .do_test-section .do-test-layout .timer-ruler-summary-section .desktop-timer {
    display: grid;
  }

  // TEST CARD SECTION
  .do_test-section .do-test-layout .cards-section {
    flex-wrap: unset;
    flex-direction: column;
    overflow-x: auto;
    overflow-y: auto;
    row-gap: 30px;
    box-sizing: border-box;
    padding: 20px 29px;
    scroll-behavior: smooth;
  }

  .cards-section .card-test {
    @apply shadow-ml;
    border-radius: 20px;
    height: auto;
    width: 100%;
    padding-inline: 90px;
  }

  .cards-section .card-test .question-content {
    i {
      display: inline-block;
    }
  }

  .cards-section .card-test .card-footer {
    button {
      display: inline-block;
    }
  }
  // END TEST CARD SECTION
}


@media (min-width: 1000px) {
  .do_test-section .do-test-layout .title-and-menu {
    h1 {
      font-size: 26px;
    }
  }
}


@media (min-width: 1200px) {
  .do_test-section .modal-go-out {
    .buttons-wrap {
      flex-direction: row;
      column-gap: 15px;
      justify-content: center;
    }
  }
}