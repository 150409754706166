@use 'sass:string';
@use 'sass:list';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './../font/fonts.scss';
@import './../utils/styles.scss';
@import './../utils/typography.scss';
@import './../utils/iconography.scss';

