.ml_404_container{
    width: 100%;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    img{
        text-align: center;
        margin: auto;
        width: 90%;
        max-width: 400px;
        margin-top: 10%;
    }
    .ml_headline{
        margin: 1em 0;
    }
}

